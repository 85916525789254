<section class="swiper-slider-hero relative block h-screen overflow-x-hidden" id="home">
    <div class="swiper-container absolute end-0 top-0 w-full h-full">
        <div class="swiper-wrapper">

            <div class="swiper-slide flex items-center overflow-hidden" >
                <div class="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image bg-red-600/5 flex items-center md:bg-top bg-center bg-no-repeat bg-cover;" 
                    style="background-image: url('assets/images/slider/slider-03.jpg');">
                    <div class="container relative">
                        <div class="grid md:grid-cols-2 grid-cols-1">
                            <div class="text-start">
                                <span class="uppercase font-semibold text-lg">Bienvenido</span>
                                <h4 class="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3 text-white">Paynalton México</h4>
                                <p class="text-lg">De tu logística nos encargamos nosotros. </p>
        
                                <div class="mt-6">
                                    <a class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-green-700 dark:bg-orange-500 text-white rounded-md">
                                        Ver más 
                                        <i class="mdi mdi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div><!--end grid-->
                    </div><!--end container-->
                </div><!-- end slide-inner --> 
            </div> <!-- end swiper-slide -->

            <div class="swiper-slide flex items-center overflow-hidden">
                <div class="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image bg-blue-600/5 flex items-center md:bg-top bg-center bg-no-repeat bg-cover;" 
                style="background-image: url('assets/images/slider/slider-05.jpg');">
                    <div class="container relative">
                        <div class="grid md:grid-cols-2 grid-cols-1">
                            <div class="text-start">
                                <span class="uppercase font-semibold text-lg">Logistica</span>
                                <h4 class="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3 text-white">Contactanos</h4>
                                <p class="text-lg">Contamos con el respaldo de nuestros socios comerciales</p>
        
                                <div class="mt-6">
                                    <a class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-green-700 dark:bg-orange-500 text-white rounded-md">View Collection <i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div><!--end grid-->
                    </div><!--end container-->
                </div><!-- end slide-inner --> 
            </div> <!-- end swiper-slide -->

            <div class="swiper-slide flex items-center overflow-hidden">
                <div class="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image bg-blue-600/5 flex items-center md:bg-top bg-center bg-no-repeat bg-cover;" 
                style="background-image: url('assets/images/slider/slider-04.jpg');">
                    <div class="container relative">
                        <div class="grid md:grid-cols-2 grid-cols-1">
                            <div class="text-start">
                                <span class="uppercase font-semibold text-lg">Servicios</span>
                                <h4 class="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3 text-white">End of Season Sale</h4>
                                <p class="text-lg">Más de 20 años de experiencia.</p>
        
                                <div class="mt-6">
                                    <a class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-green-700 dark:bg-orange-500 text-white rounded-md">
                                        Ver más 
                                        <i class="mdi mdi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div><!--end grid-->
                    </div><!--end container-->
                </div><!-- end slide-inner --> 
            </div> <!-- end swiper-slide -->

            <div class="swiper-slide flex items-center overflow-hidden">
                <div class="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image bg-blue-600/5 flex items-center md:bg-top bg-center bg-no-repeat bg-cover;" 
                style="background-image: url('assets/images/slider/slider-07.jpg');">
                    <div class="container relative">
                        <div class="grid md:grid-cols-2 grid-cols-1">
                            <div class="text-start">
                                <span class="uppercase font-semibold text-lg">Productos</span>
                                <h4 class="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3 text-white">Grandes Ofertas</h4>
                                <p class="text-lg">Visita nuestra tienda.</p>
        
                                <div class="mt-6">
                                    <a class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-green-700 dark:bg-orange-500 text-white rounded-md">
                                        Ver más 
                                        <i class="mdi mdi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div><!--end grid-->
                    </div><!--end container-->
                </div><!-- end slide-inner --> 
            </div> <!-- end swiper-slide -->

        </div>
        <!-- end swiper-wrapper -->

        <!-- swipper controls -->
        <div class="swiper-pagination"></div>
    </div><!--end container-->
</section>

<section class="relative md:py-24 py-16">


    <app-services-company></app-services-company>


</section>
