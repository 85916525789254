<app-navbar [navClass]="'defaultscroll is-sticky'"></app-navbar>
<section class="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
    <div class="md:container container-fluid relative">
        <div class="relative overflow-hidden md:rounded-md shadow dark:shadow-gray-700 h-52 bg-center bg-no-repeat bg-cover" style="background-image: url('assets/images/hero/pages.jpg');"></div>
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="md:flex">
            <div class="lg:w-1/4 md:w-1/3 md:px-3">
                <app-account-tab></app-account-tab>
            </div>

            <div class="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                    <h6 class="text-slate-400 mb-0">Las siguientes direcciones se utilizarán en la página de pago de forma predeterminada.</h6>
                    <div class="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-6">

                        @for (item of contacts(); track $index) {

                            <div class="">
                                <div class="flex items-center mb-4 justify-between">

                                    @if (item.type == 'ecommerce_invoice') {

                                        <h5 class="text-xl font-medium">Domicilio Fiscal:</h5>

                                    }

                                    @if (item.type == 'ecommerce_shipping') {

                                        <h5 class="text-xl font-medium">Domicilio de envío:</h5>
                                    }



                                    <button 
                                        (click)="modelClick(item)"
                                        type="button" 
                                        class="text-green-700 border border-green-700 hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                          </svg>
                                          
                                        <span class="sr-only">Icon description</span>
                                    </button>
                                </div>
                                <div class="pt-4 border-t border-gray-100 dark:border-gray-700">
                                    <p class="text-lg font-medium mb-2">{{item.company | titlecase}}</p>
    
                                    <ul class="list-none">
                                        <li class="flex">
                                            <i data-feather="map-pin" class="size-4 me-2 mt-1"></i>
                                            <p class="text-slate-400">{{item.city}} <br> {{item.country}}</p>
                                        </li>
    
                                        <li class="flex mt-1">
                                            <i data-feather="phone" class="size-4 me-2 mt-1"></i>
                                            <p class="text-slate-400">{{item.phone}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                        


                    </div>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>


<div *ngIf="isOpen" class="rounded-md shadow dark:shadow-gray-800 bg-slate-900/75 text-slate-900 dark:text-white fixed w-full h-screen top-0 left-0 bottom-0 right-0 flex items-center justify-center z-999">
    <div class="relative  h-auto min-w-[600px] inline-block bg-white dark:bg-slate-900">
        
        <div class="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
            <h3 class="font-bold text-lg">Datos de Contacto</h3>

                <button class="size-6 flex justify-center items-center shadow dark:shadow-gray-800 rounded-md btn-ghost" (click)="modelClick()">
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="size-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>

        </div>

        <div class="p-6 text-center">
            <app-address></app-address>
        </div>
    </div>
</div>