import { Component, inject, Input } from '@angular/core';

import { Router } from '@angular/router';

import { ProductsService } from '@services/products.service';
import { Product } from '@models/product.type';
import { CommonModule } from '@angular/common';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-list.component.html'
})
export class ProductListComponent {
  private productService = inject(ProductsService)
  products = this.productService.products
  productSelect = this.productService.productSelected

  constructor(private router:Router){

    this.productService.getProducts()

  }

  setProduct(item:Product){

    this.productSelect.set(item)
    this.router.navigate(['/product-detail']); 

  }

  addShoppingCart(item:Product){

    this.productService.addShoppingCart(item.id)


  }

}
