import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const ErrorResponseInterceptor:HttpInterceptorFn = (req, next) =>
    next(req).pipe(catchError(handleErrorResponse));

function handleErrorResponse(error:HttpErrorResponse){

    //console.log('Error en la Ruta o Interceptor', error)
    
    return throwError(()=>'error')
}