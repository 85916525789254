import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient } from "@angular/common/http";
import { routes } from './app.routes';
import { environment } from '@enviroments/environment';

/* Interceptors */
import { withFetch, withInterceptors } from '@angular/common/http';
import { ErrorResponseInterceptor } from '@interceptors/error.interceptor';
import { authInterceptor } from '@interceptors/auth.interceptor'; 
import { loaderInterceptor } from '@interceptors/loading.interceptor';

/* Stripe */
import { provideNgxStripe } from 'ngx-stripe';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([
      ErrorResponseInterceptor,
      loaderInterceptor,
      authInterceptor
    ])),
    provideNgxStripe(`${environment.stripe_pk}`),
  ],
};
