export class Product {
    id: number;
    id_category: number;
    name: string;
    description: string;
    sku: string;
    price: number;
    cost: number;
    weigth: number;
    high: number;
    width: number;
    long: number;
    colour: string;
    stock_min: number;
    stock: number;
    state: number;
    created_at: string;
    updated_at: string;
    category: string;
    images_product: ImageProduct[];
  }
  
  export class ImageProduct {
    id: number;
    id_product: number;
    path: string;
    created_at: string;
    updated_at: string;
    urlFull: string|null;
    default:number
  }
  
  export class Image{
    id:number
    item_id:number
    url:string
    default:number
  }


export class ShoppingCart {
  id: number;
  id_user: string;
  state: string;
  id_contact: number|null;
  shippingCost?:number
  amount: number;
  created_at: string;
  updated_at: string;
  shopping_cart_items: ShoppingCartItem[]=[];
}

export class ShoppingCartItem {
  id: number;
  id_shopping_cart: number;
  id_product: number;
  quantity: number;
  price: number;
  name: string;
  created_at: string;
  updated_at: string;
  images_products: ImageProduct[]=[];
}
  