import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment} from 'src/app/core/environments/environment'
import { BehaviorSubject, Observable, map, switchMap, take, tap } from 'rxjs';


import { ShoppingCart, Product } from '@models/product.type';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})

export class ProductsService {

  products = signal<Product[]>([])
  productSelected = signal<Product>(new Product())
  shoppingCart = signal<ShoppingCart>(new ShoppingCart())

  constructor(private http: HttpClient) {}


  public getProducts(search?:string){

    const url = `${environment.apiUrl}/getProducts`;

    this.http.post<any>(url, {search})
    .subscribe({
      next:(response)=>{

        const products:Product[] = response.data

        products.map(item=>{
          item.images_product.sort((a, b) => b.default - a.default)
          item.images_product.forEach(image => {
             image.path = environment.imgUrl + image.path
          });
        })
        
        this.products.set(products)

      }
    })
    
  }

  public getShoppingCart(){

    const url = `${environment.apiUrl}/getShoppingCart`;

    this.http.post<any>(url, {})
    .subscribe({
      next:(response)=>{

        if (response.data) {
          
          const shoppingCart:ShoppingCart = response.data
  
          shoppingCart.shopping_cart_items.map(item=>{
            item.images_products.sort((a, b) => b.default - a.default)
            item.images_products.forEach(image => {
               image.path = environment.imgUrl + image.path
            });
          })
  
          this.shoppingCart.set(response.data)
        }

      }
    })
    
  }

  public addShoppingCart(id_product:number){

    const url = `${environment.apiUrl}/addShoppingCart`;

    this.http.post<any>(url, {id_product})
    .subscribe({
      next:(response)=>{

        if (!response.message) {
          const shoppingCart:ShoppingCart = response.data

          shoppingCart.shopping_cart_items.map(item=>{
            item.images_products.sort((a, b) => b.default - a.default)
            item.images_products.forEach(image => {
               image.path = environment.imgUrl + image.path
            });
          })
  
          
          this.shoppingCart.update(state=>shoppingCart)

          Swal.fire({

            text: 'Producto agregado al carrito',
            icon: 'success', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:false,
            timer: 1000
          });

        }
        else{

          Swal.fire({

            text: response.message,
            icon: 'warning', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:true,
            timer: 2500
          });
          
        }

      }
    })
    
  }

  deleteItemShoppingCart(id_product:number){

    const url = `${environment.apiUrl}/deleteItemShoppingCart`;

    this.http.post<any>(url, {id_product})
    .subscribe({
      next:(response)=>{

        
        if (response.status === 200) {

          const shoppingCart:ShoppingCart = response.data

          shoppingCart.shopping_cart_items.map(item=>{
            item.images_products.sort((a, b) => b.default - a.default)
            item.images_products.forEach(image => {
               image.path = environment.imgUrl + image.path
            });
          })
  
          this.shoppingCart.update(state=>shoppingCart)

          Swal.fire({
            title: 'El producto fue eliminado',
            icon: 'success', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:true,
            timer: 1500
          });

        }

      }
    })
    
  }

  deleteShoppingCart(){

    const url = `${environment.apiUrl}/deleteShoppingCart`;

    this.http.post<any>(url, {})
    .subscribe({
      next:(response)=>{

        if (response.status === 200) {

          const shoppingCart:ShoppingCart = new ShoppingCart 

          this.shoppingCart.update(state=>shoppingCart)

          Swal.fire({
            title: 'El carrito está vacío',
            icon: 'success', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:true,
            timer: 1500
          });
        }


      }
    })
    
  }
      

}
