import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Contact } from '@models/contact.type';
import { ContactsService } from '@services/contact.service';

@Component({
  selector: 'app-address-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './address-list.component.html'
})
export class AddressListComponent {
  private contactsService = inject(ContactsService)
  contactsShipping = this.contactsService.contactsShipping

  contactSelect =this.contactsService.contactSelect

  constructor(){

    this.contactsService.getContacts()

  }

  selectAddress(contact:Contact){
    //this.contactsService.
  }

}
