import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment} from 'src/app/core/environments/environment'
import { BehaviorSubject, Observable, map, switchMap, take, tap } from 'rxjs';

import { Category } from '@models/category';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})

export class CategoriesService {

  categories = signal<Category[]>([])

  constructor(
    public http: HttpClient,
  ) {}


  public getCategories(){

    const url = `${environment.apiUrl}/getCategories`;

    this.http.post<any>(url, {})
    .subscribe({
      next:(response)=>{

        const categories:Category[] = []

        response.data.map(item=>{
          
          if (item.image != null) {
            item.path = `${environment.imgUrl}${item.image}`
          }
          if (item.state) {
            
            categories.push(item)
          }

        })
       
        this.categories.set(categories)

      }
    })
    
  }

}
