import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BackToHomeComponent } from '../../../components/back-to-home/back-to-home.component';

import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { AuthService } from '../../../core/services/auth.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule,RouterLink,BackToHomeComponent, ReactiveFormsModule ],
  templateUrl: './login.component.html'
})
export class LoginComponent {
  private authService = inject(AuthService)
  form:FormGroup;
  date:any = ''

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.date=new Date().getFullYear();

    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
    
  }


  onSubmit() {
    if (this.form.valid) {
      const email = this.form.get('email').value
      const password = this.form.get('password').value
      
      this.authService.login(email, password)
      .subscribe(response =>{

        if (response.status == 200) {

          localStorage.setItem("currentUser", JSON.stringify(response.user));
          localStorage.setItem("token", JSON.stringify(response.token));
          this.router.navigate(['/store'])
        }else{

          Swal.fire({

            text: response.message,
            icon: 'warning', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:true,
            timer: 2500
          });
          
        }

      })

    }
  }


}
