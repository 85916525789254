import { Component, inject, Input } from '@angular/core';
import { CategoriesService } from '@services/categories.service';
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [],
  templateUrl: './categories.component.html'
})
export class CategoriesComponent {
  @Input() list:boolean
  private categoriesService = inject(CategoriesService)
  private productService = inject(ProductsService)
  categories = this.categoriesService.categories

  constructor(){
    //this.categoriesService.getCategories()
  }

  selectCategory(category:string){
    this.productService.getProducts(category)
  }

}
