import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../../components/navbar/navbar.component';
import { AccountTabComponent } from '../../../components/account-tab/account-tab.component';
import { RouterLink } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { User } from '@models/auth.type';

import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-account',
  standalone: true,
  imports: [CommonModule,NavbarComponent,AccountTabComponent,RouterLink, ReactiveFormsModule],
  templateUrl: './user-account.component.html'
})
export class UserAccountComponent {

  private authService = inject(AuthService)

  user:User = new User()
  form:FormGroup

  constructor(){

    this.user = JSON.parse(localStorage.getItem('currentUser'))

    this.form = new FormGroup({
      name: new FormControl(this.user.name,[Validators.required]),
      lastname: new FormControl(this.user.lastname,[Validators.required]),
      email: new FormControl(this.user.email, [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.maxLength(16), Validators.minLength(6) ]),
      password_confirmation: new FormControl('', [Validators.maxLength(16), Validators.minLength(6) ])
    })

  }

  updateRegister(){

    this.authService.updateMyAccount(this.form.value)
    .subscribe(rs => {
      
      if(rs['status'] == 200){

        Swal.fire({
          title: rs['message'],
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        })

      }else{

        Swal.fire({
          icon: 'error',
          title: rs['message'],
          showConfirmButton: false,
          timer: 1500,
        });

      }
    });
  }

  updatePassword(){

    this.authService.updatePassword(this.form.value)
    .subscribe(rs => {
      
      if(rs['status'] == 200){

        Swal.fire({
          title: rs['message'],
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        })

        this.form.get('password').setValue('')
        this.form.get('password_confirmation').setValue('')

      }else{

        Swal.fire({
          icon: 'error',
          title: rs['message'],
          showConfirmButton: false,
          timer: 1500,
        });

      }
    });
  }

}
