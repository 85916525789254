<section class="relative h-screen flex justify-center items-center bg-slate-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="md:flex justify-center">
            <div class="lg:w-2/5">
                <div class="relative overflow-hidden rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800">
                    <div class="px-6 py-12 bg-emerald-600 text-center">
                        <i class="mdi mdi-check-circle text-white text-6xl"></i>
                        <h5 class="text-white text-xl tracking-wide uppercase font-semibold mt-2">Success</h5>
                    </div>

                    <div class="px-6 py-12 text-center">
                        <p class="text-black font-semibold text-xl dark:text-white">Felicidades! 🎉</p> 
                        <p class="text-slate-400 mt-4">
                            Su cuenta ha sido creada exitosamente. 
                            <br> 
                            Disfrute su viaje. Gracias
                        </p>
                        
                        <div class="mt-6">
                            <a routerLink="/store" 
                                class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-green-700 text-white rounded-md">Continue</a>
                        </div>
                    </div>

                    <div class="text-center p-6 border-t border-gray-100 dark:border-gray-700">
                        <div class="text-center">
                            <p class="mb-0 text-slate-400">© {{date}} Paynalton Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><!--end container-->
</section>
<app-back-to-home></app-back-to-home>
