import { Component, inject } from '@angular/core';
import { PaymentMethodsService } from '@services/payment-methods.service';


import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-methods',
  standalone: true,
  imports: [],
  templateUrl: './user-methods.component.html'
})
export class UserMethodsComponent {

  private methodService = inject(PaymentMethodsService)
  methods = this.methodService.methods

  constructor(){
    this.methodService.getPaymentMethods()

  }

  deleteMethod(paymentMethod:string){
    this.methodService.deletePaymentMethod(paymentMethod)
    .subscribe(rs=>{
      if (rs['status']==200) {
        Swal.fire({

          text: rs['message'],
          icon: 'success', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
          position: "bottom-end",
          showConfirmButton:false,
          timer: 1500
        });

        this.methodService.getPaymentMethods()

      }else{

        Swal.fire({

          text: rs['message'],
          icon: 'error', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
          position: "bottom-end",
          showConfirmButton:false,
          timer: 2500
        });

      }
    })
  }

  preferred(paymentMethod:string){
    this.methodService.updateDefaultPaymentMethod(paymentMethod)
    .subscribe(rs=>{
      if (rs['status']==200) {

        Swal.fire({

          text: rs['message'],
          icon: 'success', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
          position: "bottom-end",
          showConfirmButton:false,
          timer: 1500
        });

        this.methodService.getPaymentMethods()

      }else{

        Swal.fire({

          text: rs['message'],
          icon: 'error', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
          position: "bottom-end",
          showConfirmButton:false,
          timer: 2500
        });

      }
    })
  }

}
