<div class="relative md:-mt-48 -mt-32">
    <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
        <div class="profile-pic text-center mb-5">
            <input id="pro-img" name="profile-image" type="file" class="hidden" (change)="loadFile($event)" />
            <div>
                <div class="relative h-28 w-28 mx-auto">
                    <img src="assets/images/account.png" class="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800" id="profile-image" alt="">
                    <label class="absolute inset-0 cursor-pointer" for="pro-img"></label>
                </div>

                <div class="mt-4">
                    <h5 class="text-lg font-semibold">{{user?.name | titlecase}} {{user.lastname | titlecase}} </h5>
                    <!-- <p class="text-slate-400 text-sm ">{{user.email}}</p> -->
                </div>
            </div>
        </div>

        <div class="border-t border-gray-100 dark:border-gray-700">
            <ul class="list-none sidebar-nav mb-0 pb-0" id="navmenu-nav">
                <li class="navbar-item account-menu" [ngClass]="current ===  '/user-account' ? 'active' : '' ">
                    <a routerLink="/user-account" class="navbar-link text-slate-400 flex items-center py-2 rounded" >
                        <span class="me-2 mb-0"><i data-feather="user" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Mi cuenta</h6>
                    </a>
                </li>

                <li class="navbar-item account-menu" [ngClass]="current ===  '/user-billing' ? 'active' : '' ">
                    <a routerLink="/user-billing" class="navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span class="me-2 mb-0"><i data-feather="edit" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Direcciones</h6>
                    </a>
                </li>

                <li class="navbar-item account-menu" [ngClass]="current ===  '/user-payment' ? 'active' : '' ">
                    <a routerLink="/user-payment" class="navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span class="me-2 mb-0"><i data-feather="credit-card" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Mis Tarjetas</h6>
                    </a>
                </li>

                <li class="navbar-item account-menu" [ngClass]="current ===  '/user-orders' ? 'active' : '' ">
                    <a routerLink="/user-orders" class="navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span class="me-2 mb-0"><i data-feather="file-text" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Pedidos</h6>
                    </a>
                </li>

                <!-- <li class="navbar-item account-menu" [ngClass]="current ===  '/user-social' ? 'active' : '' ">
                    <a routerLink="/user-social" class="navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span class="me-2 mb-0"><i data-feather="share-2" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Perfil Social</h6>
                    </a>
                </li> -->

                <!-- <li class="navbar-item account-menu" [ngClass]="current ===  '/user-notification' ? 'active' : '' ">
                    <a routerLink="/user-notification" class="navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span class="me-2 mb-0"><i data-feather="bell" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Notifications</h6>
                    </a>
                </li> -->

                <!-- <li class="navbar-item account-menu" [ngClass]="current ===  '/user-setting' ? 'active' : '' ">
                    <a routerLink="/user-setting" class="navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span class="me-2 mb-0"><i data-feather="settings" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Configuración</h6>
                    </a>
                </li> -->

                <!-- <li class="navbar-item account-menu" [ngClass]="current ===  '/user-screen' ? 'active' : '' ">
                    <a routerLink="/" class="navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span class="me-2 mb-0"><i data-feather="log-out" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Salir</h6>
                    </a>
                </li> -->
            </ul>
        </div>
    </div>
</div>
