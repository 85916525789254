[
    {
        "id":1,
        "image":"assets/images/shop/black-print-t-shirt.jpg",
        "tagText":"-40% Off",
        "name":"Black Print T-Shirt",
        "desRate":"$16.00",
        "rate":"$21.00",
        "tag":true
    },
    {
        "id":2,
        "image":"assets/images/shop/fashion-shoes-sneaker.jpg",
        "tagText":"New",
        "name":"Fashion Shoes Sneaker",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":3,
        "image":"assets/images/shop/men-white-espadrilles-slip-shoes.jpg",
        "name":"Mens White Slip Shoes",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":4,
        "image":"assets/images/shop/luxurious-bag.jpg",
        "name":"Ladies Luxurious Bag",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":5,
        "image":"assets/images/shop/ladies-1.jpg",
        "name":"Ladies Green Top",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":6,
        "image":"assets/images/shop/ladies-2.jpg",
        "name":"Ladies Onepiece Wear",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":7,
        "image":"assets/images/shop/mens-jecket.jpg",
        "tagText":"Featured",
        "name":"Mens Brown Jecket",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":8,
        "image":"assets/images/shop/sweater-t-shirt.jpg",
        "name":"Sweater T-Shirt",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":9,
        "image":"assets/images/shop/trendy-t-shirt2.jpg",
        "name":"Trendy T-shirt",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":10,
        "image":"assets/images/shop/men-sun-glasses.jpg",
        "tagText":"New",
        "name":"Men Sun Glasses",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":11,
        "image":"assets/images/shop/women-s-white-low-heel-shoes.jpg",
        "tagText":"-25% Off",
        "name":"Women White Low Heel Shoes",
        "desRate":"$16.00",
        "rate":"$21.00",
        "tag":true
    },
    {
        "id":12,
        "image":"assets/images/shop/casual-men-shorts.jpg",
        "tagText":"Featured",
        "name":"Black Print T-Shirt",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":13,
        "image":"assets/images/shop/luxurious-bag2.jpg",
        "tagText":"New",
        "name":"Orange Luxurious Bag",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":14,
        "image":"assets/images/shop/smart-watch.jpg",
        "name":"Black Print T-Shirt",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":15,
        "image":"assets/images/shop/white-sandals-summer-footwear.jpg",
        "name":"Black Print T-Shirt",
        "desRate":"$16.00",
        "rate":"$21.00"
    },
    {
        "id":16,
        "image":"assets/images/shop/ladies-skirt-pair.jpg",
        "name":"Black Print T-Shirt",
        "desRate":"$16.00",
        "rate":"$21.00"
    }
]