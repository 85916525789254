import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../../components/navbar/navbar.component';
import { MobileAppComponent } from '../../../components/mobile-app/mobile-app.component';
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'app-shop-cart',
  standalone: true,
  imports: [CommonModule,RouterLink,NavbarComponent,MobileAppComponent],
  templateUrl: './shop-cart.component.html'
})
export class ShopCartComponent {

  private producService = inject(ProductsService)
  shoppingCart = this.producService.shoppingCart

  constructor(){
    this.producService.getShoppingCart()
  }

  deleteItemShoppingCart(item:any){

    this.producService.deleteItemShoppingCart(item.id_product)

  }

  deleteShoppingCart(){

    this.producService.deleteShoppingCart()
    
  }
}
