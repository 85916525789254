import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../models/auth.type';
import { environment } from '../environments/environment';

import { HttpContext, HttpContextToken } from "@angular/common/http";
//import { dateCalculateUtil } from '@utils/dateCalculateUtil';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private _route:Router
  ) {}

  login(email: string, password: string) {

    const url = `${environment.apiUrl}/authenticateEcommerce`;
    return this.http.post<any>(url, { email, password, apiTokenNull:true})

  }

  register(
    name:string,
    lastname:string,
    email:string, 
    password:string, 
    password_confirmation:string
    ) {

    const url = `${environment.apiUrl}/addAccountEcommerce`;
    return this.http.post<any>(url, { 
      name,
      lastname,
      email, 
      password, 
      password_confirmation,
      apiTokenNull:true})

  }

  contact(
    name:string,
    lastname:string,
    phone:string,
    email:string, 
    comment:string 
    ) {

    const url = `${environment.apiUrl}/ContactTheUser`;
    return this.http.post<any>(url, { 
      name,
      lastname,
      phone,
      email, 
      comment, 
      apiTokenNull:true})

  }


  public getAccountById(accountId:string){

    const url = `${environment.apiUrl}/auth/me`;
    return this.http.get<any>(url)

  }

  public updateMyAccount(user:User){

    const url = `${environment.apiUrl}/updateMyAccount`;
    return this.http.post(url, {
      name:user.name,
      lastname:user.lastname,
      email:user.email
    });

  }

  public updatePassword(data:FormData){

    const url = `${environment.apiUrl}/updateAccountPassword`;
    return this.http.post(url, {
      password:data['password'],
      password_confirmation: data['password_confirmation']
    });

  }

}
