import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { CategoriesService } from '@services/categories.service';
import { CategoriesComponent } from "../categories/categories.component";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, CategoriesComponent],
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  private categoriesService = inject(CategoriesService)
  categories = this.categoriesService.categories
  date:any

  ngOnInit(): void {
    this.date = new Date().getFullYear()
    this.categoriesService.getCategories()
  }

  services = ['Almacenaje','Fulfillment','Distribución','Logistica']


  payment = [
    'assets/images/payments/american-express.jpg','assets/images/payments/discover.jpg','assets/images/payments/mastercard.jpg','assets/images/payments/paypal.jpg','assets/images/payments/visa.jpg'
  ]
}
