<div class="container relative">
    <div class="grid grid-cols-1 justify-center text-center mb-6">
        <h5 class="font-semibold text-3xl leading-normal mb-4">Servicios</h5>
        <p class="text-slate-400 max-w-xl mx-auto">De tu logística nos encargamos nosotros. Contamos con el respaldo de nuestros socios comerciales,
            cada uno con más de 20 años de experiencia. Ademas llevamos tu inventario y proceso de fulfillment.
            ¡Por lo que tu, solo te preocuparas de vender! </p>
    </div><!--end grid-->

    <div class="grid md:grid-cols-12 grid-cols-1 pt-6 gap-6">

        <div class="lg:col-span-4 md:col-span-6 md:order-1 order-2">
            <div class="relative overflow-hidden group rounded-md shadow dark:shadow-gray-800">
                <a class="">
                    <img src="assets/images/services/almacenaje.jpg" class="h-full w-full object-cover xl:h-[738.66px] lg:h-[614.66px] rounded-md group-hover:scale-110 duration-500" alt="">
                    <span class="bg-white dark:bg-slate-900 group-hover:text-green-700 py-2 px-4 rounded-md shadow dark:shadow-gray-800 absolute mx-4 bottom-4 text-lg font-medium">
                        Almacenaje
                    </span>
                </a>
            </div>
        </div><!--end content-->
        
        <div class="lg:col-span-4 md:col-span-12 lg:order-2 order-3">
            <div class="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-6">
                <div class="relative overflow-hidden group rounded-md shadow dark:shadow-gray-800">
                    <a class="">
                        <img src="assets/images/services/fulfillment.jpg" class="group-hover:scale-110 duration-500" alt="">
                        <span class="bg-white dark:bg-slate-900 group-hover:text-green-700 py-2 px-4 rounded-md shadow dark:shadow-gray-800 absolute mx-4 bottom-4 text-lg font-medium">
                            Fulfilment
                        </span>
                    </a>
                </div><!--end content-->

                <div class="relative overflow-hidden group rounded-md shadow dark:shadow-gray-800">
                    <a class="">
                        <img src="assets/images/services/distribucion.jpg" class="group-hover:scale-110 duration-500" alt="">
                        <span class="bg-white dark:bg-slate-900 group-hover:text-green-700 py-2 px-4 rounded-md shadow dark:shadow-gray-800 absolute mx-4 bottom-4 text-lg font-medium">
                            Distribución
                        </span>
                    </a>
                </div><!--end content-->
            </div><!--end grid-->
        </div><!--end content-->
        
        <div class="lg:col-span-4 md:col-span-6 lg:order-3 order-2">
            <div class="relative overflow-hidden group rounded-md shadow dark:shadow-gray-800">
                <a class="">
                    <img src="assets/images/services/logistica.jpg" class="h-full w-full object-cover xl:h-[738.66px] lg:h-[614.66px] rounded-md group-hover:scale-110 duration-500" alt="">
                    <span class="bg-white dark:bg-slate-900 group-hover:text-green-700 py-2 px-4 rounded-md shadow dark:shadow-gray-800 absolute mx-4 bottom-4 text-lg font-medium">
                        Logistica
                    </span>
                </a>
            </div>
        </div><!--end content-->

    </div><!--end grid-->
</div><!--end container-->