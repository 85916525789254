import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '@services/loading.service';
import { catchError, Observable, throwError } from 'rxjs';


/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{



    // Clone the request object
    let newReq = req.clone();

    
    if ( localStorage.getItem('token') )
    {
        const accessToken = JSON.parse(localStorage.getItem('token')) 
        
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + accessToken),
        });

    }

    // Response
    return next(newReq).pipe(
        catchError((error) =>
        {
            // Catch "401 Unauthorized" responses
            if ( error instanceof HttpErrorResponse && error.status === 401 )
            {
                // Sign out
                //authService.signOut();

                // Reload the app
                location.reload();
            }

            return throwError(error);

        }),
    );
};
