import { CommonModule } from '@angular/common';
import { Component, HostListener, inject, Input  } from '@angular/core';
import { RouterLink, Router  } from '@angular/router';
import * as feather from 'feather-icons';
import { ClickOutsideModule } from 'ng-click-outside';
import { MenuCartComponent } from "../menu-cart/menu-cart.component";
import { MenuAccountComponent } from "../menu-account/menu-account.component";
import { ProductsService } from '@services/products.service';


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterLink, ClickOutsideModule, MenuCartComponent, MenuAccountComponent],
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {
  private productService = inject(ProductsService)
  shoppingCart = this.productService.shoppingCart

  @Input() navLight: any;
  @Input() navClass:any
  
  menu:string = '';
  subMenu:string = '';
  toggleManu:boolean = false

  // sticky-navbar 
  isSticky: boolean = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
    
  }

  constructor(private router: Router) {}
  ngAfterViewInit() {
    feather.replace();
  }

  ngOnInit() {
    const current = this.router.url;
    this.menu = current
    this.subMenu = current
    window.scrollTo(0, 0);
  }

  onEnter(event: any): void {
    const inputValue = event.target.value;

    if (inputValue) {
      this.productService.getProducts(inputValue)
    }
  }

  openManu(item:string){
    this.subMenu = item
  }
  toggleMenu(){
    this.toggleManu = !this.toggleManu
  }

  // search- modal 

  searchManu:boolean = false;
  searchModal(){
    this.searchManu=!this.searchManu
  }
  closeSearchModal() {
    this.searchManu = false;
  }

  // cart-modal 
  cart:boolean = false;
  cartModal(){
    this.cart=!this.cart
  }
  closeCartModal() {
    this.cart = false;
  }

    // user-modal 
    user:boolean = false;
    userModal(){
      this.user=!this.user
    }
    closeUserModal() {
      this.user = false;
    }
   
}
