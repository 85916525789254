export class User {
    id:number
    name:string
    lastname:string
    email: string
    password: string
    token: string
    role:string
    api:boolean
    isAdminGroup:boolean
    group:string
    roleId:number
  }
  