import { Component, inject } from '@angular/core';
import { CategoriesComponent } from "../../components/categories/categories.component";
import { ProductListComponent } from "../../components/product-list/product-list.component";
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'app-store',
  standalone: true,
  imports: [CategoriesComponent, ProductListComponent],
  templateUrl: './store.component.html'
})
export class StoreComponent {
  category:string  = null
  private productService = inject(ProductsService)
  
  heroImage = [
    {
      image:'assets/images/categories/ladies-ware.jpg',
      name:'Ropa de Mujer'
    },
    {
      image:'assets/images/categories/kids-ware.jpg',
      name:'Ropa de niños'
    },
    {
      image:'assets/images/categories/mens-ware.jpg',
      name:'Ropa para machos'
    },
  ]

  constructor(){
    this.productService.getShoppingCart()
  }

}
