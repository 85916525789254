<ul class="py-3 text-start" aria-labelledby="dropdownDefault">


    @for (item of shoppingCart().shopping_cart_items; track $index) {
        <li >
            <a href="#" class="flex items-center justify-between py-1.5 px-4">
                <span class="flex items-center">
                    <img [src]="item.images_products[0].path" class="rounded shadow dark:shadow-gray-800 w-9" alt="">
                    <span class="ms-3">
                        <span class="block font-semibold">{{item?.name}}</span>
                        <span class="block text-sm text-slate-400">${{item?.price | number:'1.2-2'}} X {{item?.quantity}}</span>
                    </span>
                </span>
    
                <span class="font-semibold">${{item?.price * item?.quantity | number:'1.2-2'}}</span>
            </a>
        </li>
    }



    <li class="border-t border-gray-100 dark:border-gray-800 my-2"></li>

    <li class="flex items-center justify-between py-1.5 px-4">
        <h6 class="font-semibold mb-0">Total($):</h6>
        <h6 class="font-semibold mb-0">${{shoppingCart().amount | number:'1.2-2'}}</h6>
    </li>

    <li class="py-1.5 px-4">
        <span class="text-center block">
            <a routerLink="/shop-cart"
                class="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-green-700 border border-green-700 text-white me-1">Ver Carrito</a>
            <a routerLink="/shop-checkout" 
            class="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-green-700 border border-green-700 text-white">Checkout</a>
        </span>
        <p class="text-sm text-slate-400 mt-1">*T&C Apply</p>
    </li>
</ul>