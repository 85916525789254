import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ServicesCompanyComponent } from "../../components/services-company/services-company.component";

import { CommonModule } from '@angular/common';

import Swiper from 'swiper';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterLink, ServicesCompanyComponent, CommonModule],
  templateUrl: './home.component.html'
})
export class HomeComponent {

  swiper!: Swiper;

  constructor() {
    register()
  }

  ngOnInit() {
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay:true,
      speed:2000,
      
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }

}
