<section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 mt-14">
            <h3 class="text-3xl leading-normal font-semibold">Checkout</h3>
        </div><!--end grid-->

        <div class="relative mt-3">
            <ul class="tracking-[0.5px] mb-0 inline-block">
                <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/">Inicio</a></li>
                <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Checkout</li>
            </ul>
        </div>
    </div><!--end container-->
</section>
<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6">
            <div class="lg:col-span-8">
                <div class="p-6 rounded-md shadow dark:shadow-gray-800">


                    <app-address-list></app-address-list>


                    <app-user-methods></app-user-methods>


                    <app-rates></app-rates>


                    <div class="mt-4">
                        <button 
                        (click)="getRatesShipping()"
                            class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-green-700 text-white rounded-md">
                            Obtener Tarifas de Envío
                    </button>
                    </div>
                </div>

            </div><!--end col-->

            <div class="lg:col-span-4">
                <div class="p-6 rounded-md shadow dark:shadow-gray-800">
                    <div class="flex justify-between items-center">
                        <h5 class="text-lg font-semibold">Carrito de compra</h5>

                        <a 
                            class="bg-orange-500 flex justify-center items-center text-white text-[10px] font-bold px-2.5 py-0.5 rounded-full h-5">
                            Productos {{shoppingCart().shopping_cart_items.length}}
                        </a>
                    </div>

                    <div class="mt-4 rounded-md shadow dark:shadow-gray-800">
                        <div class="p-3 flex justify-between items-center" *ngFor="let item of shoppingCart().shopping_cart_items">
                            <div>
                                <h5 class="font-semibold">{{item.name}}</h5>
                                <p class="text-sm text-slate-400">${{item.price | number:'1.2-2'}} X {{item.quantity}}</p>
                            </div>

                            <p class="text-slate-400 font-semibold">$ {{(item.price * item.quantity) | number:'1.2-2'}}</p>
                        </div>

                        <!-- <div class="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-slate-800 text-green-600" >
                            <div>
                                <h5 class="font-semibold">Codigo de promoción</h5>
                                <p class="text-sm text-green-600">EXAMPLECODE</p>
                            </div>

                            <p class="text-red-600 font-semibold">-$ 10</p>
                        </div> -->
                        @if (shoppingCart().shippingCost) {

                            <div class="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800">
                                <div>
                                    <h5 class="font-semibold">Costo de Envío</h5>
                                </div>
    
                                <p class="text-slate-400 font-semibold">$ {{shoppingCart().shippingCost | number:'1.2-2'}}</p>
                            </div>
                        }

                        <div class="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800">
                            <div>
                                <h5 class="font-semibold">Total</h5>
                            </div>

                            <p class="font-semibold">$ {{shoppingCart().amount | number:'1.2-2'}}</p>
                        </div>

                    </div>
                    <div class="subcribe-form mt-6">
                        <div class="relative max-w-xl">
                            
                            <button 
                                (click)="createOrder()"
                                [disabled]="!isComplete"
                                [class.bg-gray-500]="!isComplete"
                                class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-orange-500 text-white rounded-full">
                                Comprar
                            </button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


</section>