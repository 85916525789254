<section class="md:h-screen py-36 flex items-center bg-orange-500/10 dark:bg-orange-500/20 bg-center bg-no-repeat bg-cover" style="background-image: url('assets/images/hero/bg-shape.png');">
    <div class="container relative">
        <div class="grid grid-cols-1">
            <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div class="grid md:grid-cols-2 grid-cols-1 items-center">
                    <div class="relative md:shrink-0">
                        <img class="lg:h-full h-full w-full object-cover md:h-[34rem]" src="assets/images/forgot-password.jpg" alt="">
                    </div>

                    <div class="p-8 lg:px-20">
                        <div class="text-center">
                            <a routerLink="/">
                                <img src="assets/images/logo-paynalton.png" class="h-[50p] mx-auto block dark:hidden" alt="">
                                <img src="assets/images/logo-paynalton.png" class="h-[50p] mx-auto hidden dark:block" alt="">
                            </a>
                        </div>

                        <form class="text-start lg:py-20 py-8">
                            <p class="text-slate-400 mb-6">Por favor ingrese su dirección de correo electrónico. Recibirá un enlace para crear una nueva contraseña por correo electrónico.</p>
                            <div class="grid grid-cols-1">
                                <div class="mb-4">
                                    <label class="font-semibold" for="LoginEmail">Email:</label>
                                    <input id="LoginEmail" type="email" class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="nombre@ejemplo.com">
                                </div>

                                <div class="mb-4">
                                    <input type="submit" class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-green-700 text-white rounded-md w-full" value="Enviar">
                                </div>

                                <div class="text-center">
                                    <span class="text-slate-400 me-2">¿Recuerdas tu contraseña? </span><a routerLink="/login" class="text-black dark:text-white font-bold inline-block">Ingresar</a>
                                </div>
                            </div>
                        </form>

                        <div class="text-center">
                            <p class="mb-0 text-slate-400">© {{date}} Paynalton Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-back-to-home></app-back-to-home>