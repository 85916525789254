<div class="fixed top-1/4 -left-2 z-50">
    <span class="relative inline-block rotate-90">
        <input type="checkbox" class="checkbox opacity-0 absolute" id="chk" (change)="changeMode()">
        <label class="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8" for="chk">
            <i class="uil uil-moon text-yellow-500 text-xl"></i>
            <i class="uil uil-sun  text-yellow-500 text-xl"></i>
            <span class="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] w-7 h-7"></span>
        </label>
    </span>
</div>
<div class="fixed top-[40%] -left-3 z-50">
    <button id="switchRtl">
        <span class="py-1 px-3 relative inline-block rounded-b-md -rotate-90 bg-white dark:bg-slate-900 shadow-md dark:shadow dark:shadow-gray-800 font-semibold rtl:block ltr:hidden"(click)="toggleMode()" >LTR</span>
        <span class="py-1 px-3 relative inline-block rounded-b-md -rotate-90 bg-white dark:bg-slate-900 shadow-md dark:shadow dark:shadow-gray-800 font-semibold ltr:block rtl:hidden" (click)="toggleMode()">RTL</span>
    </button>
</div>
<button *ngIf="!isTopBtn()" (click)="scrollTop()" id="back-to-top" class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 size-9 text-center bg-orange-500 text-white justify-center items-center" [ngClass]="isSticky ? 'flex' : 'hidden' "><i class="mdi mdi-arrow-up"></i></button>
