<div class="rounded shadow dark:shadow-gray-800 p-4 sticky top-20">
    <h5 class="text-xl font-medium">Filtros</h5>

    <!-- <form class="mt-4">
        <div>
            <label for="searchname" class="font-medium">Search:</label>
            <div class="relative mt-2">
                <i data-feather="search" class="absolute size-4 top-[9px] end-4 text-slate-900 dark:text-white"></i>
                <input type="text" class="h-9 pe-10 rounded px-3 border border-gray-100 dark:border-gray-800 focus:ring-0 outline-none bg-white dark:bg-slate-900" name="s" id="searchItem" placeholder="Search...">
            </div>
        </div>
    </form> -->

    <!-- <div class="mt-4">
        <h5 class="font-medium">Colors:</h5>
        <ul class="list-none mt-2 space-x-1">
            <li *ngFor="let item of color" class="inline"><a class="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 inline-flex align-middle" [ngClass]="item" title="Red"></a></li>
        </ul>
    </div> -->

    <div class="mt-4">
        <h5 class="font-medium">Categorías:</h5>
        <ul class="list-none mt-2">
            <li *ngFor="let item of categories()">
                <button 
                    (click)="selectCategory(item.category)" 
                    class="text-slate-400 dark:text-gray-100">
                    <i class="mdi mdi-shopping-outline text-orange-500 me-2"></i>
                    {{item.category}}
                </button>
            </li>
        </ul>
    </div>

    <!-- <div class="mt-4">
        <h5 class="font-medium">Sizes:</h5>
        <ul class="list-none mt-2 space-x-1">
            <li *ngFor="let item of size" class="inline"><a class="px-2 mb-1 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-gray-50 hover:border-slate-900 dark:hover:border-gray-100 hover:text-white dark:hover:text-slate-900 hover:bg-slate-900 dark:hover:bg-slate-100">{{item}}</a></li>
        </ul>
    </div> -->
</div>
