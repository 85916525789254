<ul class="py-2 text-start">
    <li>
        <p class="text-slate-400 pt-2 px-4">Hola {{currentUser?.name | titlecase}}!</p>
    </li>
    <!-- <li>
        <p class="flex items-center font-medium py-2 px-4"><i data-feather="dollar-sign" class="h-4 w-4 me-2"></i> Saldo: <span class="text-orange-500 ms-2">$ 245.10</span></p>
    </li> -->
    <li>
        <a routerLink="/user-account" class="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white">
            <i data-feather="user" class="h-4 w-4 me-2"></i>Mi cuenta</a>
    </li>
    <li>
        <a routerLink="/user-billing" class="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white">
            <i data-feather="edit" class="h-4 w-4 me-2"></i>Direcciones</a>
    </li>
    <li>
        <a routerLink="/user-orders" class="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white">
            <i data-feather="file-text" class="h-4 w-4 me-2"></i>Pedidos</a>
    </li>

    <li>
        <a routerLink="/user-payment" class="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white">
            <i data-feather="credit-card" class="h-4 w-4 me-2"></i>Mis Tarjetas</a>
    </li> 
    <li class="border-t border-gray-100 dark:border-gray-800 my-2"></li>
    <li>
        <a routerLink="/login" class="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white">
            <i data-feather="log-out" class="h-4 w-4 me-2"></i>Salir</a>
    </li>
</ul>