export class Contact {
  id: number;
  user_id: number;
  company: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  rfc: string;
  zipcode: string;
  country: string;
  city: null;
  international: number;
  state: string;
  delegation: string;
  location: string;
  street: string;
  outdoor: string;
  interior: string;
  property: string;
  reference: string;
  settlement_type_code: string;
  road_type_code: string;
  state_code: string;
  status: string;
  code_country: null;
  type: string;
}