<div class="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-6">
    <div class="p-6 border-b border-gray-100 dark:border-gray-700">
        <h5 class="text-xl font-semibold">Métodos de Pago Disponibles</h5>
        <p class="text-slate-400 mt-2">El método de pago principal se utiliza de forma predeterminada.</p>
    </div>

    <div class="px-6">
        <ul>
            @for (item of methods(); track $index) {

                <li class="flex py-6 first:border-none border-t border-gray-100 dark:border-gray-700">
                    <div class="flex items-center md:w-1/12">
                        <input 
                        type="radio" 
                        class="form-radio border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2" 
                        name="radio-colors" 
                        (click)="preferred(item.id)"
                        [checked]="item.preferred"
                        value="1" >
                    </div>
                    <div class="flex items-start md:w-6/12">
                        <img [src]="item.imageUrl" class="rounded shadow dark:shadow-gray-800 w-12" alt="">

                        <div class="ms-3">
                            <p class="font-semibold">{{item.brand}} **** **** {{item.last4}}</p>
                            <p class="text-slate-400 text-sm">Expira {{item.exp_month}}/{{item.exp_year}}</p>
                        </div>
                    </div>
                    
                    <div class="flex items-center md:w-2/12">
                        <button 
                            (click)="deleteMethod(item.id)"
                            class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 hover:bg-red-600 text-red-600 hover:text-white rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                              </svg>
                              
                              
                        </button>
                    </div>
                </li>
            }

        </ul>
    </div>
</div>
