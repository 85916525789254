<app-navbar [navClass]="'defaultscroll is-sticky'"></app-navbar>

<section class="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
    <div class="md:container container-fluid relative">
        <div class="relative overflow-hidden md:rounded-md shadow dark:shadow-gray-700 h-52 bg-center bg-no-repeat bg-cover" 
        style="background-image: url('assets/images/hero/pages.jpg');"></div>
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="md:flex">
            <div class="lg:w-1/4 md:w-1/3 md:px-3">
                <app-account-tab></app-account-tab>
            </div>

            <div class="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                <div class="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                    <div class="p-6 md:flex justify-between items-center border-b border-gray-100 dark:border-gray-700">
                        <div class="mb-4 md:mb-0">
                            <h5 class="text-xl font-semibold">Nuevo Método de Pago</h5>
                        </div>
                        
                    </div>

                    <div class="md:w-3/4 md:px-3 mt-5 ml-5 ">
                  
                        <form [formGroup]="checkoutForm" autocomplete="off">
                          
                            <ngx-stripe-elements
                                [stripe]="stripe"
                                [elementsOptions]="elementsOptions"
                            >
                                <ngx-stripe-card [options]="cardOptions" />
                            </ngx-stripe-elements>
                        </form>


                        <!-- (click)="createToken()" -->
                        <div class="mt-10 text-center">

                            <button  
                                (click)="createToken()"
                                [disabled]="isVisible"
                                class="mb-5 py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-green-700 text-white rounded-md">
                                Agregar tarjeta
                            </button>
                        </div>
                          
                    </div>
                </div>

                <app-user-methods></app-user-methods>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>
