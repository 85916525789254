import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment} from 'src/app/core/environments/environment'


import { Contact } from '@models/contact.type';


@Injectable({
  providedIn: 'root'
})

export class ContactsService {

  contacts = signal<Contact[]>([])
  contactsShipping = signal<Contact[]>([])
  contactSelect = signal<Contact>(new Contact())

  constructor(
    public http: HttpClient,
  ) {}


  public getContacts(){

    const url = `${environment.apiUrl}/getAddressEcommece`;

    this.http.get<any>(url)
    .subscribe({
      next:(response)=>{

        this.contacts.set(response.data)
        
        this.contacts().map(item=>{
          if (item.type === 'ecommerce_shipping') {
            this.contactSelect.set(item)
            this.contactsShipping().push(item)
          }
        })

      }
    })
    
  }

  public saveContact(contact:Contact){

    const url = `${environment.apiUrl}/saveContactEcommerce`;

    this.http.post<any>(url, {contact})
    .subscribe({
      next:(response)=>{

        return response

      }
    })
    
  }

}
