import { Component, effect, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Contact } from '@models/contact.type';
import { ContactsService } from '@services/contact.service';

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './address.component.html'
})
export class AddressComponent {
  private contactService = inject(ContactsService)
  contactSelect = this.contactService.contactSelect
  form:FormGroup

  constructor(){

    this.form = new FormGroup({

      id:                     new FormControl(),
      type:                   new FormControl(),
      name:                   new FormControl('', [Validators.required]),
      lastname:               new FormControl('', [Validators.required]),
      company:                new FormControl('', [Validators.required]),
      phone:                  new FormControl('', [Validators.required, ]),
      rfc:                    new FormControl('', [Validators.required, ]),
      email:                  new FormControl('', [Validators.required, Validators.email]),
      property:               new FormControl(''),
      street:                 new FormControl('', [Validators.required]),
      outdoor:                new FormControl('', [Validators.required]),
      interior:               new FormControl('', [Validators.maxLength(20)]),
      reference:              new FormControl(''),
      location:               new FormControl('', [Validators.required]),
      delegation:             new FormControl(''),
      state:                  new FormControl('', [Validators.required]),
      zipcode:                new FormControl('', [Validators.required]),
      settlement_type_code:   new FormControl('', [Validators.required]),
      road_type_code:         new FormControl('', [Validators.required]),
      country:                new FormControl(''),
      international:          new FormControl(''),
      city:                   new FormControl(''),
      code_country:           new FormControl('')

    })


    effect(() => {

      if (this.contactSelect().id) {

        this.loadContact(this.contactSelect())
        
      }
      
    });
    

  }

  loadContact(contact:Contact){
    let typeContact:string
    typeContact = 'Destination'

    this.form.patchValue({
      id:                   contact.id,
      type:                 typeContact,
      zipcode:              contact.zipcode,
      name:                 contact.name,
      lastname:             contact.lastname,
      company:              contact.company,
      email:                contact.email,
      phone:                contact.phone,
      rfc:                  contact.rfc,
      property:             contact.property,
      street:               contact.street,
      interior:             contact.interior,
      outdoor:              contact.outdoor,
      location:             contact.location,
      state:                contact.state,
      delegation:           contact.delegation,
      reference:            contact.reference,
      settlement_type_code: contact.settlement_type_code,
      road_type_code:       contact.road_type_code,
      international:        contact.international,
      country:              contact.country,
      city:                 contact.city,
      code_country:         contact.code_country
    })

  }
}
