import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../../components/navbar/navbar.component';
import { AccountTabComponent } from '../../../components/account-tab/account-tab.component';
import { ContactsService } from '@services/contact.service';
import { AddressComponent } from "../../../components/address/address.component";
import { Contact } from '@models/contact.type';

@Component({
  selector: 'app-user-billing',
  standalone: true,
  imports: [CommonModule, RouterLink, NavbarComponent, AccountTabComponent, AddressComponent],
  templateUrl: './user-billing.component.html'
})
export class UserBillingComponent {
  private contactService = inject(ContactsService)
  contacts = this.contactService.contacts
  contactSelect = this.contactService.contactSelect

  constructor(){
    this.contactService.getContacts()
  }

  isOpen:boolean = false

  modelClick(contact?:Contact){


    this.isOpen=!this.isOpen;

    if (contact) {
      this.contactSelect.set(contact)
    }

  }

}
