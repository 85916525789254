import { Routes } from '@angular/router';
import { IndexOneComponent } from './pages/index/index-one/index-one.component';
import { IndexTwoComponent } from './pages/index/index-two/index-two.component';
import { IndexThreeComponent } from './pages/index/index-three/index-three.component';
import { IndexFourComponent } from './pages/index/index-four/index-four.component';
import { ShopGridComponent } from './pages/shop/shop-grid/shop-grid/shop-grid.component';
import { ShopGridLeftSidebarComponent } from './pages/shop/shop-grid/shop-grid-left-sidebar/shop-grid-left-sidebar.component';
import { ShopGridRightSidebarComponent } from './pages/shop/shop-grid/shop-grid-right-sidebar/shop-grid-right-sidebar.component';
import { ShopListComponent } from './pages/shop/shop-list/shop-list/shop-list.component';
import { ShopListLeftSidebarComponent } from './pages/shop/shop-list/shop-list-left-sidebar/shop-list-left-sidebar.component';
import { ShopListRightSidebarComponent } from './pages/shop/shop-list/shop-list-right-sidebar/shop-list-right-sidebar.component';
import { ProductDetailOneComponent } from './pages/shop/product-detail/product-detail-one/product-detail-one.component';
import { ProductDetailTwoComponent } from './pages/shop/product-detail/product-detail-two/product-detail-two.component';
import { ProductDetailThreeComponent } from './pages/shop/product-detail/product-detail-three/product-detail-three.component';
import { ShopCartComponent } from './pages/shop/shop-cart/shop-cart.component';
import { ShopCheckoutComponent } from './pages/shop/shop-checkout/shop-checkout.component';
import { OurStoreComponent } from './pages/shop/our-store/our-store.component';
import { BrandsComponent } from './pages/shop/brands/brands.component';
import { CompareProductComponent } from './pages/shop/compare-product/compare-product.component';
import { RecentlyViewedProductComponent } from './pages/shop/recently-viewed-product/recently-viewed-product.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { UserAccountComponent } from './pages/account/user-account/user-account.component';
import { UserBillingComponent } from './pages/account/user-billing/user-billing.component';
import { UserPaymentComponent } from './pages/account/user-payment/user-payment.component';
import { UserInvoiceComponent } from './pages/account/user-invoice/user-invoice.component';
import { UserSocialComponent } from './pages/account/user-social/user-social.component';
import { UserNotificationComponent } from './pages/account/user-notification/user-notification.component';
import { UserSettingComponent } from './pages/account/user-setting/user-setting.component';
import { EmailConfirmationComponent } from './pages/email-template/email-confirmation/email-confirmation.component';
import { EmailCartComponent } from './pages/email-template/email-cart/email-cart.component';
import { EmailOffersComponent } from './pages/email-template/email-offers/email-offers.component';
import { EmailOrderSuccessComponent } from './pages/email-template/email-order-success/email-order-success.component';
import { EmailGiftVoucherComponent } from './pages/email-template/email-gift-voucher/email-gift-voucher.component';
import { EmailResetPasswordComponent } from './pages/email-template/email-reset-password/email-reset-password.component';
import { EmailItemReviewComponent } from './pages/email-template/email-item-review/email-item-review.component';
import { BlogsComponent } from './pages/blog/blogs/blogs.component';
import { BlogDetailComponent } from './pages/blog/blog-detail/blog-detail.component';
import { CareerComponent } from './pages/career/career.component';
import { HelpcenterComponent } from './pages/helpcenter/helpcenter/helpcenter.component';
import { HelpcenterFaqsComponent } from './pages/helpcenter/helpcenter-faqs/helpcenter-faqs.component';
import { HelpcenterGuidesComponent } from './pages/helpcenter/helpcenter-guides/helpcenter-guides.component';
import { HelpcenterSupportComponent } from './pages/helpcenter/helpcenter-support/helpcenter-support.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { SignupComponent } from './pages/auth/signup/signup.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { SignupSuccessComponent } from './pages/auth/signup-success/signup-success.component';
import { LockScreenComponent } from './pages/auth/lock-screen/lock-screen.component';
import { TermsComponent } from './pages/utility/terms/terms.component';
import { PrivacyComponent } from './pages/utility/privacy/privacy.component';
import { ComingsoonComponent } from './pages/special/comingsoon/comingsoon.component';
import { MaintenanceComponent } from './pages/special/maintenance/maintenance.component';
import { ErrorComponent } from './pages/special/error/error.component';
import { SaleComponent } from './pages/sale/sale.component';
import { ContactComponent } from './pages/contact/contact.component';
import { UserOrdersComponent } from './pages/account/user-orders/user-orders.component';
import { HomeComponent } from '@pages/home/home.component';
import { OrderSuccessComponent } from '@pages/order-success/order-success.component';
import { AuthGuard } from './core/guard/auth.guard';
import { StoreComponent } from '@pages/store/store.component';

export const routes: Routes = [

    /* Rutas Libres */
    {path:'', redirectTo: '/home', pathMatch: 'full'},
    {path:'home', component:HomeComponent},
    {path:'login', component:LoginComponent},
    {path:'signup', component:SignupComponent},
    {path:'forgot-password', component:ForgotPasswordComponent},
    {path:'signup-success',component:SignupSuccessComponent},

    {path:'aboutus', component:AboutusComponent},
    {path:'contact',component:ContactComponent},

    /* Rutas Firmadas */
    {path:'order-success/:orderKey', component:OrderSuccessComponent, canActivate:[AuthGuard]},
    {path:'store', component:StoreComponent, canActivate:[AuthGuard]},
    {path:'products',component:ShopGridLeftSidebarComponent, canActivate:[AuthGuard]},
    {path:'shop-grid-right-sidebar',component:ShopGridRightSidebarComponent, canActivate:[AuthGuard]},
    {path:'shop-grid-right-sidebar',component:ShopGridRightSidebarComponent, canActivate:[AuthGuard]}, // No utilizado
    {path:'product-detail',component:ProductDetailTwoComponent, canActivate:[AuthGuard]},

    {path:'user-account', component:UserAccountComponent, canActivate:[AuthGuard]},
    {path:'user-orders', component:UserOrdersComponent, canActivate:[AuthGuard]},
    {path:'user-billing', component:UserBillingComponent, canActivate:[AuthGuard]},
    {path:'user-payment', component:UserPaymentComponent, canActivate:[AuthGuard]},
    {path:'user-invoice', component:UserInvoiceComponent, canActivate:[AuthGuard]},
    {path:'user-social', component:UserSocialComponent, canActivate:[AuthGuard]},
    {path:'user-notification', component:UserNotificationComponent, canActivate:[AuthGuard]},
    {path:'user-setting', component:UserSettingComponent, canActivate:[AuthGuard]},

    {path:'shop-cart', component:ShopCartComponent, canActivate:[AuthGuard]},
    {path:'shop-checkout', component:ShopCheckoutComponent, canActivate:[AuthGuard]},
    

    /* {path:'index-fashion-two', component:IndexTwoComponent},
    {path:'index-fashion-three', component:IndexThreeComponent},
    {path:'index-fashion-four', component:IndexFourComponent},
    {path:'shop-grid', component:ShopGridComponent},
    {path:'shop-list', component:ShopListComponent},
    {path:'shop-list-left-sidebar', component:ShopListLeftSidebarComponent},
    {path:'shop-list-right-sidebar', component:ShopListRightSidebarComponent},
    {path:'product-detail-one', component:ProductDetailOneComponent},
    {path:'product-detail-three',component:ProductDetailThreeComponent}, */

    /* {path:'our-store', component:OurStoreComponent},
    {path:'brands', component:BrandsComponent},
    {path:'compare-product', component:CompareProductComponent},
    {path:'recently-viewed-product', component:RecentlyViewedProductComponent}, */


    /* {path:'email-confirmation', component:EmailConfirmationComponent},
    {path:'email-cart', component:EmailCartComponent},
    {path:'email-offers', component:EmailOffersComponent},
    {path:'email-order-success', component:EmailOrderSuccessComponent},
    {path:'email-gift-voucher', component:EmailGiftVoucherComponent},
    {path:'email-reset-password',component:EmailResetPasswordComponent},
    {path:'email-item-review', component: EmailItemReviewComponent},
    {path:'blogs', component:BlogsComponent},
    {path:'blog-detail', component:BlogDetailComponent},
    {path:'career', component:CareerComponent},
    {path:'helpcenter', component:HelpcenterComponent},
    {path:'helpcenter-faqs', component:HelpcenterFaqsComponent},
    {path:'helpcenter-guides', component:HelpcenterGuidesComponent},
    {path:'helpcenter-support', component:HelpcenterSupportComponent}, */

    /* {path:'lock-screen', component:LockScreenComponent},
    {path:'terms', component:TermsComponent},
    {path:'privacy', component:PrivacyComponent},
    {path:'comingsoon', component:ComingsoonComponent},
    {path:'maintenance', component:MaintenanceComponent},
    {path:'error', component:ErrorComponent},
    {path:'sale', component:SaleComponent}, */
    
    {path:'**', component:ErrorComponent},
];
