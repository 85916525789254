import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import feather from 'feather-icons';

@Component({
  selector: 'app-services-company',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './services-company.component.html'
})
export class ServicesCompanyComponent {
  
  aboutData = [
    {
      icon:'help-circle',
      title:'Almacenaje',
      desc:'Almacenamiento de productos generales.',
      link:'/helpcenter-faqs'
    },
    {
      icon:'bookmark',
      title:'Fulfillment',
      desc:'Despachamos y preparamos tus pedidos.',
      link:'/helpcenter-guides'
    },
    {
      icon:'settings',
      title:'Distribución',
      desc:'Distribución Nacional e Internacional',
      link:'/helpcenter-support'
    },
  ]

  ngAfterViewInit(): void {
    feather.replace();
  }
}
