import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '@services/loading.service';
import { catchError, Observable, throwError, finalize  } from 'rxjs';


/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const loaderInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{


    /* Loader */    
    const loaderService = inject(LoaderService)
    const isVisible = loaderService.isVisible

    isVisible.update(state => !state)

    const newReq = null

    return next(req).pipe(
        // Ocultar el spinner cuando la solicitud finaliza (exitoso o error)
        finalize(() => isVisible.update(state => !state))
      );
};
