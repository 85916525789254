<form [formGroup]="form">
    <div class="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-5">
        <div class="lg:col-span-6">
            <label class="form-label font-semibold">Compañia : <span class="text-red-600">*</span></label>
            <input formControlName="company" type="text" class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2" placeholder="First Name:" id="firstname" name="name" required="">
        </div>

        <div class="lg:col-span-6">
            <label class="form-label font-semibold">Nombres : <span class="text-red-600">*</span></label>
            <input formControlName="name" type="text" class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2" placeholder="Last Name:" id="lastname" name="name" required="">
        </div>

        <div class="lg:col-span-6">
            <label class="form-label font-semibold">Apellidos</label>
            <div class="relative mt-2">
                <span class="absolute top-0.5 start-0.5 w-9 h-9 text-xl bg-gray-100 dark:bg-slate-800 inline-flex justify-center items-center text-dark dark:text-white rounded" id="basic-addon1"><i class="mdi mdi-at"></i></span>
                <input formControlName="lastname" type="text" class="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Username" required>
            </div>
        </div>

        <div class="lg:col-span-6">
            <label class="form-label font-semibold">Email : <span class="text-red-600">*</span></label>
            <input formControlName="email" type="email" class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2" placeholder="Email" name="email" required="">
        </div>

        <!-- <div class="lg:col-span-12">
            <label class="form-label font-semibold">Address : <span class="text-red-600">*</span></label>
            <input type="text" class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2" placeholder="Address:" id="address" name="name" required="">
        </div>

        <div class="lg:col-span-12">
            <label class="form-label font-semibold">Address 2 : </label>
            <input type="text" class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2" placeholder="Address:" id="address" name="name" required="">
        </div>

        <div class="lg:col-span-4">
            <label class="font-semibold">Country:</label>
            <select class="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
                <option value="USA">USA</option>
                <option value="CAD">Canada</option>
                <option value="CHINA">China</option>
            </select>
        </div>

        <div class="lg:col-span-4">
            <label class="font-semibold">State:</label>
            <select class="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
                <option value="CAL">California</option>
                <option value="TEX">Texas</option>
                <option value="FLOR">Florida</option>
            </select>
        </div>

        <div class="lg:col-span-4">
            <label class="form-label font-semibold">Zip Code : <span class="text-red-600">*</span></label>
            <input type="number" class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2" placeholder="Zip:" id="zipcode" name="number" required="">
        </div> 

        <div class="lg:col-span-12">
            <div class="flex items-center w-full mb-0">
                <input class="form-checkbox rounded border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="sameaddress">
                <label class="form-check-label text-slate-400" for="sameaddress">Shipping address is the same as my billing address</label>
            </div>

            <div class="flex items-center w-full mb-0">
                <input class="form-checkbox rounded border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="savenexttime">
                <label class="form-check-label text-slate-400" for="savenexttime">Save this information for next time</label>
            </div>
        </div>-->
    </div>
</form>