<div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">

    <div class="lg:col-span-7 md:col-span-6" *ngIf="products().length == 0" >
        <div class="lg:ms-8">
            <h6 class="text-orange-500 font-semibold uppercase text-lg">Productos no disponibles</h6>
            <h5 class="font-semibold text-3xl leading-normal my-4">La lista está vacía</h5>            
        </div>
    </div>

    <div *ngFor="let item of products()" class="group">
        <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
            <img [src]="item.images_product[0].path" class="group-hover:scale-110 duration-500" alt="">
    
            <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                <button 
                        (click)="addShoppingCart(item)"
                        [disabled]="item.state === 0"
                        [ngClass]="{' bg-green-700': item.state===1 , 'bg-gray-400': item.state===0 }"
                    class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center text-white w-full rounded-md">
                    <span *ngIf="item.state===1">Agregar</span>
                    <span *ngIf="item.state===0">No disponible</span>
                </button>
            </div>
    
            <!-- <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                <li><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="heart" class="size-4"></i></a></li>
                <li class="mt-1"><a routerLink="/shop-item-detail" class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="eye" class="size-4"></i></a></li>
                <li class="mt-1"><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="bookmark" class="size-4"></i></a></li>
            </ul> -->
    
            <ul *ngIf="item.name" class="list-none absolute top-[10px] start-4">
                <li><a class="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.name}}</a></li>
            </ul>
    
            <ul *ngIf="item.category === 'New' " class="list-none absolute top-[10px] start-4">
                <li><a class="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.colour}}</a></li>
            </ul>
    
            <ul *ngIf="item.sku === 'Featured' "  class="list-none absolute top-[10px] start-4">
                <li><a class="bg-emerald-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.sku}}</a></li>
            </ul>
        </div>
    
        <div class="mt-4">
            <a (click)="setProduct(item)" class="hover:text-orange-500 text-lg font-medium">{{item.name}}</a>
            <div class="flex justify-between items-center mt-1">
                <p>
                    {{item.price | number:'1.2-2'}}
                    <!-- <del class="text-slate-400">{{item.price}}</del> -->
                </p>
                <!-- <ul class="font-medium text-amber-400 list-none">
                    <li class="inline"><i class="mdi mdi-star"></i></li>
                    <li class="inline"><i class="mdi mdi-star"></i></li>
                    <li class="inline"><i class="mdi mdi-star"></i></li>
                    <li class="inline"><i class="mdi mdi-star"></i></li>
                    <li class="inline"><i class="mdi mdi-star"></i></li>
                </ul> -->
            </div>
        </div>
    </div>
</div><!--end grid-->