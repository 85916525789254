<div class="container relative md:mt-24 mt-16">
    <div class="grid md:grid-cols-12 grid-cols-1 items-center">
        <div class="lg:col-span-5 md:col-span-6">
            <img src="assets/images/envelope.svg" class="mx-auto d-block" alt="">
        </div>

        <div class="lg:col-span-7 md:col-span-6">
            <span class="bg-orange-500/5 text-orange-500 text-xs font-bold px-2.5 py-0.5 rounded h-5">Mobile Apps</span>
            <h4 class="font-semibold text-3xl leading-normal my-4">Available for your <br> Smartphones</h4>
            <p class="text-slate-400 max-w-xl mb-0">Upgrade your style with our curated sets. Choose confidence, embrace your unique look.</p>
            <div class="my-5">
                <a><img src="assets/images/app.png" class="m-1 inline-block" alt=""></a>

                <a><img src="assets/images/playstore.png" class="m-1 inline-block" alt=""></a>
            </div>

            <div class="inline-block">
                <div class="pt-4 flex items-center border-t border-gray-100 dark:border-gray-800">
                    <i data-feather="smartphone" class="me-2 text-orange-500 h-10 w-10"></i>
                    <div class="content">
                        <h6 class="text-base font-medium">Install app now on your cellphones</h6>
                        <a class="text-orange-500">Learn More <i class="mdi mdi-arrow-right"></i></a> 
                    </div>
                </div>
            </div>
        </div>
    </div><!--end gird-->
</div><!--end container-->
