import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../components/navbar/navbar.component';

@Component({
  selector: 'app-aboutus',
  standalone: true,
  imports: [CommonModule,RouterLink,NavbarComponent],
  templateUrl: './aboutus.component.html'
})
export class AboutusComponent {
  isModal:boolean = false

  onModalClick(){
    this.isModal=!this.isModal;
  }

  data = [
    {
      icon:'mdi mdi-account-multiple',
      title:'Somos',
      desc:'Somos una empresa 100% mexicana. Creada para satisfacer las necesidades logísticas, en cualquier momento de tu cadena de suministro. Contamos con socios comerciales que nos ayudan a presentarte el traje a la medida, con el mejor de los precios, calidad y servicio.',
    },
    {
      icon:'mdi mdi-asterisk',
      title:'Misión',
      desc:'Brindar un servicio de calidad, ofreciendo soluciones logísticas en cada etapa de la cadena de suministro, cumpliendo con los estándares de alta calidad y siempre de la mano de la mejora continua. ',
    },
    {
      icon:'mdi mdi-arrange-send-to-back',
      title:'Visión',
      desc:'Ser reconocidos como lideres en México en brindar soluciones logisticas, con los procesos y tecnologia más inovadoras. ',
    },

  ]


}
