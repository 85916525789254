
<section class="relative md:py-24 py-16">

    <div class="container relative md:mt-24 mt-16">
        <div class="grid grid-cols-1 justify-center text-center mb-4">
            <h6 class="text-green-700 font-semibold uppercase text-lg">Sobre Nosotros</h6>
            
            <div class="flex justify-center items-center">
                <video class="w-full md:w-2/3 rounded-lg shadow-lg" controls autoplay muted>
                  <source src="assets/videos/nosotros.mp4" type="video/mp4" >
                  Tu navegador no soporta el elemento de video.
                </video>
            </div>
              

        </div><!--end grid-->

        <div class="grid md:grid-cols-3 grid-cols-1 mt-6 gap-6">
            <!-- Content -->
            <div *ngFor="let item of data" class="p-6 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-md bg-white dark:bg-slate-900">
                <i class="text-4xl text-orange-500" [ngClass]="item.icon"></i>

                <div class="content mt-6">
                    <a class="title h5 text-xl font-medium hover:text-orange-500">{{item.title}}</a>
                    <p class="text-slate-400 mt-3">{{item.desc}}</p>
                    
                    <div class="mt-4" hidden>
                        <a class="text-orange-500">Read More <i class="mdi mdi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div><!--end container-->

</section>
<div *ngIf="isModal" class="w-full h-screen bg-slate-900/80 fixed top-0 left-0 bottom-0 right-0 z-999 flex items-center justify-center">
    <div class="w-full h-full px-5 md:px-40 md-py-20 py-5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55431.05581015953!2d-95.461302!3d29.735948000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c36647a52ab1%3A0x70a301678672cb!2sBriargrove%20Park%2C%20Houston%2C%20TX%2C%20USA!5e0!3m2!1sen!2sin!4v1710322657489!5m2!1sen!2sin" width="100%" height="100%" title="myfram" loading="lazy">
        </iframe>
    </div>
    <button class="text-slate-400 absolute top-[20px] right-[20px]" (click)="onModalClick()">
        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="size-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    </button>
</div>