import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BackToHomeComponent } from '../../../components/back-to-home/back-to-home.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { environment } from '@enviroments/environment';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CommonModule,RouterLink, BackToHomeComponent, ReactiveFormsModule],
  templateUrl: './signup.component.html'
})
export class SignupComponent {
  private authService = inject(AuthService)
  form:FormGroup;
  date:any = ''

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.date=new Date().getFullYear();

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });

    if (!environment.production) {
      this.form.patchValue({
        name:'Victor Hugo',
        lastname:'Coronado',
        email:'victorhugocoronado1980@gmail.com',
        password:'password',
      })
    }
    
  }


  onSubmit() {
    if (this.form.valid) {
      const name = this.form.get('name').value
      const lastname = this.form.get('lastname').value
      const email = this.form.get('email').value
      const password = this.form.get('password').value
      const password_confirmation = this.form.get('password').value
      
      
      this.authService.register(
        name,
        lastname,
        email, 
        password, 
        password_confirmation
      )
      .subscribe(response =>{

        if (response.status == 200) {

          this.router.navigate(['/signup-success'])

        }else{

          Swal.fire({

            text: response.message,
            icon: 'warning', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:false,
            timer: 2500
          });

        }

      })

    }
  }
}
