import { Component, inject } from '@angular/core';
import { LoaderService } from '@services/loading.service';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [],
  templateUrl: './loader.component.html'
})
export class LoaderComponent {
  private service = inject(LoaderService)
  isModalVisible = this.service.isVisible
}
