import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '@components/navbar/navbar.component';
import { ProductDetailComponent } from '@components/product-detail/product-detail.component';
import { ProductDetailTabComponent } from '@components/product-detail-tab/product-detail-tab.component';
import { TaglineComponent } from '@components/tagline/tagline.component';

import ProductData from '../../../../data/productData.json';
import { ProductListComponent } from "../../../../components/product-list/product-list.component";

@Component({
  selector: 'app-product-detail-two',
  standalone: true,
  imports: [CommonModule, RouterLink, NavbarComponent, ProductDetailComponent, ProductDetailTabComponent, TaglineComponent, ProductListComponent],
  templateUrl: './product-detail-two.component.html'
})
export class ProductDetailTwoComponent {
  product = ProductData

  productImage = [
    {
      id:1,
      image:'assets/images/shop/mens-jecket.jpg'
    },
    {
      id:2,
      image:'assets/images/shop/mens-jecket-3.jpg'
    },
    {
      id:3,
      image:'assets/images/shop/mens-jecket-left.jpg'
    },
    {
      id:4,
      image:'assets/images/shop/mens-jecket-back.jpg'
    },
    {
      id:5,
      image:'assets/images/shop/mens-jecket-4.jpg'
    },
  ]


}
