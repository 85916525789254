import { Component } from '@angular/core';

@Component({
  selector: 'app-tagline',
  standalone: true,
  imports: [],
  templateUrl: './tagline.component.html',
  styleUrl: './tagline.component.scss'
})
export class TaglineComponent {

}
