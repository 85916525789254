import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AccountTabComponent } from '../../../components/account-tab/account-tab.component';
import { NavbarComponent } from '../../../components/navbar/navbar.component';
import { OrdersService } from '@services/orders.service';

@Component({
  selector: 'app-user-orders',
  standalone: true,
  imports: [CommonModule,NavbarComponent,AccountTabComponent, NgFor, NgIf],
  templateUrl: './user-orders.component.html'
})
export class UserOrdersComponent {
  private orderService = inject(OrdersService)
  orders = this.orderService.orders

  constructor(){
    this.orderService.getOrders()
  }
}
