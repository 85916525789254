import { CommonModule, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'app-menu-cart',
  standalone: true,
  imports: [NgFor, CommonModule, RouterLink],
  templateUrl: './menu-cart.component.html'
})
export class MenuCartComponent {

  private productService = inject(ProductsService)
  shoppingCart = this.productService.shoppingCart

}
