@if (!list) {

    <div class="container relative">
        <div class="grid grid-cols-1 justify-center text-center mb-6">
            <h5 class="font-semibold text-3xl leading-normal mb-4">Magnificos productos</h5>
            <p class="text-slate-400 max-w-xl mx-auto">Compre los últimos productos de las colecciones más populares</p>
        </div>
    
        <div  class="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 pt-6 gap-6">
            
            @for (item of categories(); track $index) {
    
                <button
                    (click)="selectCategory(item.category)"  
                    class="text-center hover:text-orange-500">
                    <img [src]="item.path" class="rounded-full shadow dark:shadow-gray-800" alt="">
                    <span class="text-xl font-medium mt-3 block">{{item.category}}</span>
                </button>
    
            }
            
        </div>
    </div>

}

@if (list) {

    <ul class="list-none footer-list mt-6" >
        @for (item of categories(); track $index) {
            <li class=":first-mt-0 mt-[10px]">
                <button 
                    (click)="selectCategory(item.category)"  
                    class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                    <i class="mdi mdi-chevron-right"></i> 
                    {{item.category}}
                </button>
            </li>
        }
    </ul>
    

}
