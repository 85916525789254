<div class="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
    <div class="lg:col-span-3 md:col-span-5">
        <div class="sticky top-20">
            <ul class="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                <li role="presentation">
                    <button class="px-4 py-2 text-start text-base font-semibold rounded-md w-full hover:text-orange-500 duration-500" [ngClass]="activeTab === 1 ? 'text-white bg-green-700 hover:text-white' : '' " (click)="onTabClick(1)">Descripción</button>
                </li>
                <li role="presentation">
                    <button class="px-4 py-2 text-start text-base font-semibold rounded-md w-full hover:text-orange-500 mt-3 duration-500" [ngClass]="activeTab === 2 ? 'text-white bg-green-700 hover:text-white' : '' " (click)="onTabClick(2)">Información Adicional</button>
                </li>
                <li role="presentation">
                    <button class="px-4 py-2 text-start text-base font-semibold rounded-md w-full hover:text-orange-500 mt-3 duration-500" [ngClass]="activeTab === 3 ? 'text-white bg-green-700 hover:text-white' : '' " (click)="onTabClick(3)">Comentarios</button>
                </li>
            </ul>
        </div>
    </div>

    <div class="lg:col-span-9 md:col-span-7">
        <div id="myTabContent" class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
            <div [ngClass]="activeTab === 1 ? '' : 'hidden'">
                <p class="text-slate-400">{{product().description}}</p>
            </div>

            <div [ngClass]="activeTab === 2 ? '' : 'hidden'">
                <table class="w-full text-start">
                    <tbody>
                        <tr class="bg-white dark:bg-slate-900">
                            <td class="font-semibold pb-4" style="width: 100px;">Color</td>
                            <td class="text-slate-400 pb-4">{{product().colour}}</td>
                        </tr>

                        <tr class="bg-white dark:bg-slate-900 border-t border-gray-100 dark:border-gray-700">
                            <td class="font-semibold py-4">Material</td>
                            <td class="text-slate-400 py-4">Algodón</td>
                        </tr>

                        <tr class="bg-white dark:bg-slate-900 border-t border-gray-100 dark:border-gray-700">
                            <td class="font-semibold pt-4">Tamaño</td>
                            <td class="text-slate-400 pt-4">S, M, L, XL, XXL</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div [ngClass]="activeTab === 3 ? '' : 'hidden'">
                

                <div *ngFor="let item of reviewData" class="mt-8 first:mt-0">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center">
                            <img [src]="item.image" class="h-11 w-11 rounded-full shadow" alt="">

                            <div class="ms-3 flex-1">
                                <a class="text-lg font-semibold hover:text-orange-500 duration-500">{{item.name}}</a>
                                <p class="text-sm text-slate-400">{{item.time}}</p>
                            </div>
                        </div>

                        <a class="text-slate-400 hover:text-orange-500 duration-500 ms-5"><i class="mdi mdi-reply"></i> Reply</a>
                    </div>
                    <div class="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
                        <ul class="list-none inline-block text-orange-400">
                            <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                            <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                            <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                            <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                            <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                            <li class="inline text-slate-400 font-semibold">5.0</li>
                        </ul>

                        <p class="text-slate-400 italic">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                    </div>
                </div>

                <div class="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
                    <h5 class="text-lg font-semibold">Deja un comentario:</h5>

                    <form class="mt-8">
                        <div class="grid lg:grid-cols-12 lg:gap-6">
                            <div class="lg:col-span-6 mb-5">
                                <div class="text-start">
                                    <label for="name" class="font-semibold">Su nombre:</label>
                                    <div class="form-icon relative mt-2">
                                        <i data-feather="user" class="w-4 h-4 absolute top-3 start-4"></i>
                                        <input name="name" id="name" type="text" class="ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Name :">
                                    </div>
                                </div>
                            </div>

                            <div class="lg:col-span-6 mb-5">
                                <div class="text-start">
                                    <label for="email" class="font-semibold">Email:</label>
                                    <div class="form-icon relative mt-2">
                                        <i data-feather="mail" class="w-4 h-4 absolute top-3 start-4"></i>
                                        <input name="email" id="email" type="email" class="ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Email :">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid grid-cols-1">
                            <div class="mb-5">
                                <div class="text-start">
                                    <label for="comments" class="font-semibold">Comentario:</label>
                                    <div class="form-icon relative mt-2">
                                        <i data-feather="message-circle" class="w-4 h-4 absolute top-3 start-4"></i>
                                        <textarea name="comments" id="comments" class="ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Message :"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" id="submit" name="send" class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full">Enviar Comentario</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
