import { CommonModule } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { NavbarComponent } from '../../../components/navbar/navbar.component';
import { AccountTabComponent } from '../../../components/account-tab/account-tab.component';
import { UntypedFormBuilder, FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

import {
  injectStripe,
  StripeElementsDirective,
  StripeCardComponent,
  StripeService
} from 'ngx-stripe';
import {
  StripeElementsOptions,
  StripeCardElementOptions
} from '@stripe/stripe-js';

import { environment } from '@enviroments/environment';
import { PaymentMethodsService } from '@services/payment-methods.service';
import Swal from 'sweetalert2';
import { UserMethodsComponent } from "../user-methods/user-methods.component";
import { LoaderService } from '@services/loading.service';

@Component({
  selector: 'app-user-payment',
  standalone: true,
  imports: [CommonModule, NavbarComponent, AccountTabComponent, ReactiveFormsModule,
    StripeElementsDirective,
    StripeCardComponent, UserMethodsComponent],
  templateUrl: './user-payment.component.html'
})
export class UserPaymentComponent {
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;

  // Replace with your own public key
  stripe = injectStripe(`${environment.stripe_pk}`);


  private paymentMethodsService = inject( PaymentMethodsService)
  private loaderService = inject(LoaderService)
  isVisible:boolean = false

  private readonly fb = inject(UntypedFormBuilder);

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'es',
  };

  checkoutForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]]
  });

  userName:string

  constructor() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.userName = currentUser.name

  }

  createToken() {
    this.isVisible = true
    const name = this.userName;

    this.stripe
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          
          let paymentMethod:string = result.token.id;

          this.cretePaymentMethod(paymentMethod)

        } else if (result.error) {

          Swal.fire({

            text: result.error.message,
            icon: 'error', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:false,
            timer: 4500
          });
          this.isVisible = false

        }
      });
  }

  cretePaymentMethod(paymentMethod:string){

    const name = this.userName;

    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.card.element,
        billing_details: {name},
      })
      .subscribe((result) => {
        this.checkoutForm.reset()
        this.saveCard(result.paymentMethod.id)
    });
    

  }

  saveCard(paymentMethod:string){
    this.paymentMethodsService.createPaymentMethod(paymentMethod)
    .subscribe(rs=>{
      if (rs['status']==200) {

        Swal.fire({
          title:rs['message'],
          icon: 'success', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
          position: "bottom-end",
          showConfirmButton:false,
          timer: 1500
        });
        
        this.paymentMethodsService.getPaymentMethods()
        this.isVisible = false

      }else{
        Swal.fire({
          title:rs['message'],
          icon: 'error', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
          position: "bottom-end",
          showConfirmButton:false,
          timer: 4500
        });
      }

      
    })
  }


}
