import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CategoriesService } from '@services/categories.service';
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [CommonModule,RouterLink],
  templateUrl: './filter.component.html'
})
export class FilterComponent {
  private categoriesService = inject(CategoriesService)
  private productService = inject(ProductsService)
  categories = this.categoriesService.categories

  selectCategory(category:string){
    this.productService.getProducts(category)
  }
  
  color=[
    'bg-red-600','bg-indigo-600','bg-emerald-600','bg-slate-900','bg-gray-400','bg-orange-600','bg-violet-600'
  ]

  size = [
    'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'
  ]
}
