<section class="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 mt-14">
            <h3 class="text-3xl leading-normal font-semibold">Todos los productos</h3>
        </div>

        <div class="relative mt-3">
            <ul class="tracking-[0.5px] mb-0 inline-block">
                <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/">Inicio</a></li>
                <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Productos</li>
            </ul>
        </div>
    </div>
</section>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid md:grid-cols-12 sm:grid-cols-2 grid-cols-1 gap-6">

            <div class="lg:col-span-3 md:col-span-4 sticky">

                <app-filter></app-filter>
                
            </div>

            <div class="lg:col-span-9 md:col-span-8">


                <app-product-list></app-product-list>


            </div>
        </div>
    </div>
</section>
