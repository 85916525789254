<section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 mt-14">
            <h3 class="text-3xl leading-normal font-semibold">{{title}}</h3>
        </div><!--end grid-->

        <div class="relative mt-3">
            <ul class="tracking-[0.5px] mb-0 inline-block">
                <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/">Inicio</a></li>
                <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Örden Completada</li>
            </ul>
        </div>
    </div><!--end container-->
</section>
<section class="relative ">

    <div class="flex justify-center h-screen">
        <div class="w-2/3 p-4">
            <tbody>

                <tr>
                    <td style="padding: 24px 16px; background: #f8fafc; display: block; text-align: center;">
                        <img src="assets/images/order-success.svg" width="200px" style="margin: 0 auto;" alt="">
                        <h1 style="font-weight: 600; margin: 16px 0 0; font-size: 32px;">Gracias!</h1>
                    </td>
                </tr>
        
                <tr>
                    <td style="padding: 16px 16px 0; color: #161c2d;">
                        <p style="margin: 0; font-size: 18px; font-weight: 500;">Hello, Harry!</p>
                        <p style="margin-bottom: 0; color: #94a3b8;">Gracias por el éxito de su pedido. El pago se realizó correctamente y su pedido está en camino.
                            <span style="font-weight: 500; color: #161c2d;">Transaction ID: {{orderKey}}</span></p>
                    </td>
                </tr>
        
            </tbody>
        </div>
    </div>


</section>