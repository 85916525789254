import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment} from 'src/app/core/environments/environment'


import { Contact } from '@models/contact.type';


@Injectable({
  providedIn: 'root'
})

export class LoaderService {

  isVisible = signal<boolean>(false)

  constructor(

  ) {}


}
