<div class="grid md:grid-cols-2 grid-cols-1 gap-6 items-center">
    <div class="">
        <ul class="product-imgs flex list-none">
            <li class="w-1/5">
                <ul class="img-select list-none">
                    @for (image of product().images_product; track $index) {

                        <li class="p-px">
                            <a (click)="setActiveImage(image.path)">
                                <img [src]="image.path" class="shadow dark:shadow-gray-800" alt="">
                            </a>
                        </li>

                    }
                </ul>
            </li>

            <li class="img-display shadow dark:shadow-gray-800 m-px w-4/8">
                <div class="img-showcase flex w-full duration-500">
                    <img [src]="activeImage" class="min-w-full" alt="shoe image">
                </div>
            </li>
        </ul>
    </div><!--end content-->

    <div class="">
        <h5 class="text-2xl font-semibold">{{product().name}}</h5>
        <div class="mt-2">
            <span class="text-slate-400 font-semibold me-1">
                    ${{product().price | number:'1.2-2'}} 
                    <!-- <del class="text-red-600">$21USD</del> -->
            </span>
        
            <ul class="list-none inline-block text-orange-400">
                <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                <li class="inline"><i class="mdi mdi-star text-lg"></i></li>
                <li class="inline text-slate-400 font-semibold">4.8 (45)</li>
            </ul>
        </div>
        
        <div class="mt-4">
            <h5 class="text-lg font-semibold">Descripción :</h5>
            <p class="text-slate-400 mt-2">{{product().description}}</p>
        
            <ul class="list-none text-slate-400 mt-4">
                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i> Digital Marketing Solutions for Tomorrow</li>
                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i> Our Talented & Experienced Marketing Agency</li>
                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i> Create your own skin to match your brand</li>
            </ul>
        </div>
        
        <div class="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-4">
            <div class="flex items-center">
                <h5 class="text-lg font-semibold me-2">Tamaño:</h5>
                <div class="space-x-1">
                    <a class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white">S</a>
                    <a class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white">M</a>
                    <a class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white">L</a>
                    <a class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white">XL</a>
                </div>
            </div><!--end content-->
        
            <div class="flex items-center">
                <h5 class="text-lg font-semibold me-2">Cantidad:</h5>
                <div class="qty-icons ms-3 space-x-0.5">
                    <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus">-</button>
                    <input min="0" name="quantity" value="0" type="number" class="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity">
                    <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus">+</button>
                </div>
            </div><!--end content-->
        
            <div class="flex items-center">
                <h5 class="text-lg font-semibold me-2">Colores:</h5>
                <div class="space-x-2">
                    <a class="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-red-600 inline-flex align-middle" title="Red"></a>
                    <a class="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-orange-600 inline-flex align-middle" title="Orange"></a>
                    <a class="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-slate-900 inline-flex align-middle" title="Black"></a>
                    <a class="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-gray-300 inline-flex align-middle" title="Gray"></a>
                </div>
            </div><!--end content-->
        </div><!--end grid-->
        
        <div class="mt-4 space-x-1">
            <!-- <a class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2">
                Comprar Ahora</a> -->

            <button (click)="addShoppingCart()" [disabled]="product().state === 0"
                [ngClass]="{' bg-green-700': product().state===1 , 'bg-gray-400': product().state===0 }"
                class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center text-white w-full rounded-md">
                <span *ngIf="product().state===1">Agregar</span>
                <span *ngIf="product().state===0">No disponible</span>
            </button>
                
        </div>
    </div><!--end content-->
</div><!--end grid-->


