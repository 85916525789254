import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { environment } from '@enviroments/environment';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule,NavbarComponent, ReactiveFormsModule],
  templateUrl: './contact.component.html'
})
export class ContactComponent {
  isModal:boolean = false
  private authService = inject(AuthService)
  form:FormGroup;

  onModalClick(){
    this.isModal=!this.isModal;
  }

  ngOnInit(): void {

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
    });

    if (!environment.production) {
      this.form.patchValue({
        name:'Victor Hugo',
        lastname:'Coronado',
        phone:'5566332222',
        email:'victorhugocoronado1980@gmail.com',
        comment:'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. ',
      })
    }
    
  }

  onSubmit() {
    if (this.form.valid) {
      const name = this.form.get('name').value
      const lastname = this.form.get('lastname').value
      const phone = this.form.get('phone').value
      const email = this.form.get('email').value
      const comment = this.form.get('comment').value
      
      
      this.authService.contact(
        name,
        lastname,
        phone,
        email, 
        comment, 
      )
      .subscribe(response =>{

        if (response.status == 200) {

          Swal.fire({
            text: response.message,
            icon: 'success', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:false,
            timer: 1000
          });

        }else{

          Swal.fire({
            text: response.message,
            icon: 'warning', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
            position: "bottom-end",
            showConfirmButton:false,
            timer: 2500
          });

        }

      })

    }
  }

}
