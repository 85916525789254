import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-success',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './order-success.component.html'
})
export class OrderSuccessComponent {
  title:string='Órden Completada'
  orderKey:string
  
  constructor(private route: ActivatedRoute){

    if (this.route.snapshot.paramMap.get('orderKey')) {
      
      this.orderKey = this.route.snapshot.paramMap.get('orderKey');

    }

  }

}
