<app-navbar [navClass]="'defaultscroll is-sticky'"></app-navbar>

<section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 mt-14">
            <h3 class="text-3xl leading-normal font-semibold">Carrito de Compra</h3>
        </div><!--end grid-->

        <div class="relative mt-3">
            <ul class="tracking-[0.5px] mb-0 inline-block">
                <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/">Inicio</a></li>
                <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Carrito de Compra</li>
            </ul>
        </div>
    </div><!--end container-->
</section>
<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid lg:grid-cols-1">

            <div class="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
                <table class="w-full text-start">
                    <thead class="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                        <tr>
                            <th scope="col" class="p-4 w-4">
                                @if (shoppingCart().shopping_cart_items.length > 0) {
                                    <a 
                                        href="javascript:void(0)" 
                                        (click)="deleteShoppingCart()" 
                                        title="Eliminar todos los elementos del carrito">
                                        <i class="mdi mdi-window-close text-red-600"></i>
                                    </a>
                                }
                            </th>
                            <th scope="col" class="text-start p-4 min-w-[220px]">Producto</th>
                            <th scope="col" class="p-4 w-24 min-w-[100px]">Precio</th>
                            <th scope="col" class="p-4 w-56 min-w-[220px]">Cantidad</th>
                            <th scope="col" class="p-4 w-24 min-w-[100px]">Total($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (item of shoppingCart().shopping_cart_items; track $index) {
                            <tr class="bg-white dark:bg-slate-900">
                                
                                <td class="p-4"><a (click)="deleteItemShoppingCart(item)"><i class="mdi mdi-window-close text-red-600"></i></a></td>
                                <td class="p-4">
                                    <span class="flex items-center">
                                        <img [src]="item.images_products[0].path" class="rounded shadow dark:shadow-gray-800 w-12" alt="">
                                        <span class="ms-3">
                                            <span class="block font-semibold">{{item.name}}</span>
                                        </span>
                                    </span>
                                </td>
                                <td class="p-4 text-center">$ {{item.price}}</td>
                                <td class="p-4 text-center">{{item.quantity}}</td>
                                <!-- <td class="p-4 text-center" hidden>
                                    <div class="qty-icons">
                                        <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus">-</button>
                                        <input min="0" name="quantity" value="3" type="number" class="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity mx-1">
                                        <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus">+</button>
                                    </div>
                                </td> -->
                                <td class="p-4  text-end">$ {{item.price * item.quantity}}</td>
                            </tr>

                        }
                    </tbody>
                </table>

            </div>


            @if (shoppingCart().shopping_cart_items.length === 0) {

                <a routerLink="/"
                    class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white mt-2">
                    Ver Productos</a>
            }

            @if (shoppingCart().shopping_cart_items.length > 0) {

                <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    <div class="lg:col-span-9 md:order-1 order-3">

                        <div class="space-x-1">
                        
                            <a 
                                routerLink="/shop-checkout"
                                class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-green-700 text-white rounded-md mt-2">
                                Continuar Compra
                            </a>

                        </div>

                    </div>

                    <div class="lg:col-span-3 md:order-2 order-1">
                        <ul class="list-none shadow dark:shadow-gray-800 rounded-md">
                            <li class="flex justify-between p-4">
                                <span class="font-semibold text-lg">Subtotal :</span>
                                <span class="text-slate-400">$ {{shoppingCart().amount}}</span>
                            </li>
                            <li class="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800">
                                <span class="font-semibold text-lg">IVA :</span>
                                <span class="text-slate-400">$ 150</span>
                            </li>
                            <li class="flex justify-between font-semibold p-4 border-t border-gray-200 dark:border-gray-600">
                                <span class="font-semibold text-lg">Total :</span>
                                <span class="font-semibold">$ 1650</span>
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    </div><!--end container-->

    <!-- <app-mobile-app></app-mobile-app> -->
</section>
