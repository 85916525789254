

<app-navbar [navClass]="'defaultscroll is-sticky'"></app-navbar>

<section class="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
    <div class="md:container container-fluid relative">
        <div class="relative overflow-hidden md:rounded-md shadow dark:shadow-gray-700 h-52  bg-center bg-no-repeat bg-cover" style="background-image: url('assets/images/hero/pages.jpg');"></div>
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="md:flex">
            <div class="lg:w-1/4 md:w-1/3 md:px-3">
                <app-account-tab></app-account-tab>
            </div>

            <div class="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                <h5 class="text-lg font-semibold mb-6">Pedidos</h5>
                <div class="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
                    <table class="w-full text-start text-slate-500 dark:text-slate-400">
                        <thead class="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                            <tr class="text-start">
                                <th scope="col" class="px-2 py-3 text-center" style="min-width: 100px;">Order no.</th>
                                <th scope="col" class="px-2 py-3 text-start" style="min-width: 170px;">Fecha</th>
                                <th scope="col" class="px-2 py-3 text-start" style="min-width: 170px;">Estatus</th>
                                <th scope="col" class="px-2 py-3 text-start" style="min-width: 170px;">Total</th>
                                <th scope="col" class="px-2 py-3 text-center" style="min-width: 90px;">Items</th>
                                <th scope="col" class="px-2 py-3 text-start" style="min-width: 75px;">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of orders()" class="bg-white dark:bg-slate-900 text-start">
                                <th class="px-2 py-3 text-center" scope="row">{{item.id}}</th>
                                <td class="px-2 py-3 text-start">{{item.created_at | date:'mediumDate'}}</td>
                                <td *ngIf="item.state === 'payment' " class="px-2 py-3 text-start text-green-600">Pagado</td>
                                <td *ngIf="item.state === 'Processing' " class="px-2 py-3 text-start text-slate-400">Processing</td>
                                <td *ngIf="item.state === 'Canceled' " class="px-2 py-3 text-start text-red-600">Canceled</td>
                                <td class="px-2 py-3 text-start">{{item.balance | number:'1.2-2'}}</td>
                                <td class="px-2 py-3 text-center" > <span class="text-slate-400 ">{{item.order_items.length}}</span></td>
                                <td class="px-2 py-3 text-start">
                                    <button class="text-orange-500">Ver 
                                        <i class="mdi mdi-chevron-right"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h5 class="text-lg font-semibold my-6">Pedido Seleccionado</h5>


            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>
