export class Rate {
  rateToken: string;
  provider: string;
  serviceId: number;
  description: string;
  type: string;
  weight: number;
  frequency: string;
  coverage_extended: boolean | number;
  ocurre: boolean | number;
  dateOfDelivery: string;
  messageDateOfDelivery?: string;
  price_type: string;
  price_service: string;
  price_coverage: string;
  price_iva: string;
  price_total: string;
  price: string;
  charge_fuel: string;
  tax_fuel: string;
  currency: string;
  status: number;
  isInternational?: null;
  isImportation?: boolean;
  imageUrl:string;
}