import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment} from 'src/app/core/environments/environment'
import { BehaviorSubject, Observable, map, switchMap, take, tap } from 'rxjs';

import { Order } from '@models/order.type';
import { Rate } from '@models/rate.type';

import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})

export class OrdersService {

  orders = signal<Order[]>([])
  rates = signal<Rate[]>([])
  rateselect = signal<Rate>(new Rate())

  constructor(
    public http: HttpClient,
  ) {}


  public getOrders(){

    const url = `${environment.apiUrl}/getOrdersEcommerce`;

    this.http.post<any>(url, {})
    .subscribe({
      next:(response)=>{

        const orders:Order[] = response.data

        orders.map(order=>{

          order.order_items.forEach(item => {

            item.images_products[0].path = environment.imgUrl + item.images_products[0].path

             
          });
        })
        
        this.orders.set(orders)

      }
    })
    
  }

  getRateShipping(id_contact:number){
    //const url = 'assets/data/rates.json' //prueba
    const url = `${environment.apiUrl}/rateEcommerce`;

    //this.http.get<any>(url) //prueba
    this.http.post<any>(url, {id_contact})
    .subscribe({
      next:(response)=>{
        
        const rates:Rate[] = response.data

        rates.map(item=>{

          item.imageUrl = `assets/images/providers/${item.provider}.jpg`

        })

        this.rates.set(response.data)

      }
    })

  }

  createOrderEcommerce(data:any){

    const url = `${environment.apiUrl}/createOrderEcommerce`;

    return this.http.post<any>(url, {
      rateToken: data.rateToken,
      id_invoice: data.id_invoice,
      paymentIntent: data.paymentIntent,
      paymentMethod: data.paymentMethod,
      totalPayment: data.totalPayment,
    })

    
  }

}
