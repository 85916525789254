export const environment = {
  production: false,
  appName:'Paynalton Local',
  //apiUrl: 'http://localhost:8000/api/v1', //LOCAL
  //apiUrl: 'https://prodv2.paynalton.mx/api/v1', //PROD
  apiUrl: 'https://dev.paynalton.mx/api/v1', //DEV
  //stripe_pk:'pk_test_51L99R7D6x5gyDJJYDDQ5HD2MzOCBBXNkCshmYjhJu5J73K3xECXJHINOCPfmxHEBxCL1HGRTJyxUdlRUOfJyKjMh00fd1Zosu2', //Paynalton prueba
  stripe_pk:'pk_test_51HPaw3Juhvx3rFbqCYGcWqu317zNnJbxd54yaM0didohDYLUeGZTapVohCZyLoRPEjGG72mCYBl5wScnNzOyGnEE00HRyW3tOH',  //JOE

  googleId: '224856352350-0tikh49pqm61jk7jhk7di4camktl4ckf.apps.googleusercontent.com',
  facebookId:'',
  imgUrl: 'https://dev.paynalton.mx/storage/'
};

