<div class="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-6">
    <div class="p-6 border-b border-gray-100 dark:border-gray-700">
        <h5 class="text-xl font-semibold">Domicilios de Entrega</h5>
    </div>

    <div class="px-6">
        <ul>
            @for (item of contactsShipping(); track $index) {

                <li class="flex py-6 first:border-none border-t border-gray-100 dark:border-gray-700">
                    
                    <!-- Solo muestra si no existe contato predeterminado -->
                     @if (contactsShipping().length > 1) {

                        <div class="flex items-center md:w-1/12">
                            <input 
                            type="radio" 
                            class="form-radio border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2" 
                            name="radio-address" 
                            (click)="selectAddress(item)"
                            
                            value="1" >
                        </div>

                     }

                    <!-- [checked]="item.preferred" -->
                    <div class="flex items-start md:w-6/12">
                        <div class="ms-3">
                            <p class="font-semibold">{{item.company | titlecase}}</p>
                            <p class="text-slate-400 text-sm">{{item.city | titlecase}} {{item.location | titlecase}} #{{item.outdoor}} {{item.interior}}</p>
                        </div>
                    </div>
                    
                    <div class="flex items-center md:w-2/12">

                    </div>
                </li>
            }

        </ul>
    </div>
</div>