import { CommonModule } from '@angular/common';
import { Component, inject, effect  } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'app-product-detail',
  standalone: true,
  imports: [CommonModule,RouterLink],
  templateUrl: './product-detail.component.html'
})
export class ProductDetailComponent {

  private productService = inject(ProductsService)
  product = this.productService.productSelected

  imageSelect:string
  
  activeImage:string = this.product().images_product[0].path;

  constructor() {

    effect(() => {

      this.activeImage = this.product().images_product[0].path;

    });
  }

  setActiveImage(imagePath:string){
    
    this.activeImage=imagePath;

  }

  addShoppingCart(){
    if (this.product().id) {
      
      this.productService.addShoppingCart(this.product().id)
      
    }

  }
}
