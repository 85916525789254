<footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">    
    <div class="container relative">
        <div class="grid grid-cols-12">
            <div class="col-span-12">
                <div class="py-[60px] px-0">
                    <div class="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div class="lg:col-span-3 md:col-span-12">
                            <a class="text-[22px] focus:outline-none">
                                <img src="assets/images/logo.png" class="h-[120px]">
                            </a>
                            <p class="mt-6 text-gray-300">Mejora tu estilo con nuestros conjuntos seleccionados. Elija confianza, adopte su apariencia única.</p>
                            <ul class="list-none mt-6 space-x-1">
                                <li class="inline"><a href="" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><i data-feather="shopping-cart" class="h-4 w-4 align-middle" title="Buy Now"></i></a></li>
                                <li class="inline"><a href="" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><i data-feather="dribbble" class="h-4 w-4 align-middle" title="dribbble"></i></a></li>
                                <li class="inline"><a href="" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><i data-feather="linkedin" class="h-4 w-4 align-middle" title="Linkedin"></i></a></li>
                                <li class="inline"><a href="" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><i data-feather="facebook" class="h-4 w-4 align-middle" title="facebook"></i></a></li>
                                <li class="inline"><a href="" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><i data-feather="instagram" class="h-4 w-4 align-middle" title="instagram"></i></a></li>
                                <li class="inline"><a href="" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><i data-feather="twitter" class="h-4 w-4 align-middle" title="twitter"></i></a></li>
                                <li class="inline"><a href="mailto:email@gmail.com" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><i data-feather="mail" class="h-4 w-4 align-middle" title="email"></i></a></li>
                            </ul><!--end icon-->
                        </div><!--end col-->
                
                        <div class="lg:col-span-6 md:col-span-12">
                            <h5 class="tracking-[1px] text-gray-100 font-semibold">Compra de Productos</h5>

                            <div class="grid md:grid-cols-12 grid-cols-1">
                                <div  class="col-span-4">

                                    <app-categories [list]="true"></app-categories>
                                    
                                    <!-- <ul class="list-none footer-list mt-6">
                                        @for (item of services; track $index) {
                                            <li  
                                                class=":first-mt-0 mt-[10px]">
                                                <a class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                                                    <i class="mdi mdi-chevron-right"></i> 
                                                    {{item}}
                                                </a>
                                            </li>
                                        }
                                    </ul> -->

                                </div>
                            </div>
                        </div>

                        <div class="lg:col-span-3 md:col-span-4">
                            <h5 class="tracking-[1px] text-gray-100 font-semibold">Hoja informativa</h5>
                            <p class="mt-6">Regístrese y reciba los últimos consejos por correo electrónico.</p>
                            <form>
                                <div class="grid grid-cols-1">
                                    <div class="my-3">
                                        <label class="form-label">Escribe tu correo electrónico <span class="text-red-600">*</span></label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="mail" class="size-4 absolute top-3 start-4"></i>
                                            <input type="email" class="ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0 placeholder:text-gray-200 outline-none" placeholder="Email" name="email" required="">
                                        </div>
                                    </div>
                                
                                    <button type="submit" id="submitsubscribe" 
                                        name="send" 
                                        class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-green-700 text-white rounded-md">
                                        Suscribirse</button>
                                </div>
                            </form>
                        </div><!--end col-->
                    </div><!--end grid-->
                </div><!--end col-->
            </div>
        </div><!--end grid-->

        <div class="grid grid-cols-1">
            <div class="py-[30px] px-0 border-t border-slate-800">
                <div class="grid lg:grid-cols-4 md:grid-cols-2">
                    <div class="flex items-center lg:justify-center">
                        <i class="mdi mdi-truck-check-outline align-middle text-lg mb-0 me-2"></i>
                        <h6 class="mb-0 font-medium">Entrega Gatuita</h6>
                    </div><!--end content-->

                    <div class="flex items-center lg:justify-center">
                        <i class="mdi mdi-archive align-middle text-lg mb-0 me-2"></i>
                        <h6 class="mb-0 font-medium">Envío sin contacto</h6>
                    </div><!--end content-->

                    <div class="flex items-center lg:justify-center">
                        <i class="mdi mdi-cash-multiple align-middle text-lg mb-0 me-2"></i>
                        <h6 class="mb-0 font-medium">Garantía de devolución de dinero</h6>
                    </div><!--end content-->

                    <div class="flex items-center lg:justify-center">
                        <i class="mdi mdi-shield-check align-middle text-lg mb-0 me-2"></i>
                        <h6 class="mb-0 font-medium">Pagos seguros</h6>
                    </div><!--end content-->
                </div><!--end grid-->
            </div><!--end-->
        </div><!--end grid-->
    </div><!--end container-->

    <div class="py-[30px] px-0 border-t border-slate-800">
        <div class="container relative text-center">
            <div class="grid md:grid-cols-2 items-center">
                <div class="md:text-start text-center">
                    <p class="mb-0">© {{date}} Paynalton. Todos los derechos Reservados.
                        </p>
                </div>

                <ul class="list-none md:text-end text-center mt-6 md:mt-0 space-x-1">
                    <li *ngFor="let item of payment" class="inline"><a ><img [src]="item" class="max-h-6 rounded inline" title="American Express" alt=""></a></li>
                </ul>
            </div><!--end grid-->
        </div><!--end container-->
    </div>
</footer>
