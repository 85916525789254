import { CommonModule } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';

import { RouterLink, Router  } from '@angular/router';
import { User } from '@models/auth.type';
import { PaymentMethodsService } from '@services/payment-methods.service';

@Component({
  selector: 'app-account-tab',
  standalone: true,
  imports: [CommonModule,RouterLink],
  templateUrl: './account-tab.component.html'
})
export class AccountTabComponent {
  user:User

  image:any  = ''
  loadFile(event:any){
   this.image = document.getElementById(event.target.name);
   this.image.src = URL.createObjectURL(event.target.files[0]);
  }

  constructor(private router: Router) {
    if (localStorage.getItem('currentUser') != null) {
      
      this.user = JSON.parse(localStorage.getItem('currentUser'))
    }
  }

  current:any = '';
  ngOnInit() {

    this.current = this.router.url;

  }
}
