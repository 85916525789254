<app-navbar [navClass]="'defaultscroll is-sticky'"></app-navbar>

<section class="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
    <div class="md:container container-fluid relative">
        <div class="relative overflow-hidden md:rounded-md shadow dark:shadow-gray-700 h-52 bg-center bg-no-repeat bg-cover" style="background-image: url('assets/images/hero/pages.jpg');"></div>
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="md:flex">
            <div class="lg:w-1/4 md:w-1/3 md:px-3">
                <app-account-tab></app-account-tab>
            </div>

            <div class="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                    <h5 class="text-lg font-semibold mb-4">Datos personales :</h5>
                    <form [formGroup]="form" autocomplete="off">
                        <div class="grid lg:grid-cols-2 grid-cols-1 gap-5">
                            <div>
                                <label class="form-label font-medium">Nombres : <span class="text-red-600">*</span></label>
                                <div class="form-icon relative mt-2">
                                    <i data-feather="user" class="w-4 h-4 absolute top-3 start-4"></i>
                                    <input 
                                        type="text" 
                                        class="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" 
                                        placeholder="Nombres:" 
                                        formControlName="name"
                                    >
                                </div>
                            </div>

                            <div>
                                <label class="form-label font-medium">Apellidos : <span class="text-red-600">*</span></label>
                                <div class="form-icon relative mt-2">
                                    <i data-feather="user" class="w-4 h-4 absolute top-3 start-4"></i>
                                    <input 
                                        type="text" 
                                        class="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" 
                                        placeholder="Apellidos:" 
                                        formControlName="lastname"
                                    >
                                </div>
                            </div>

                            <div>
                                <label class="form-label font-medium">Email : <span class="text-red-600">*</span></label>
                                <div class="form-icon relative mt-2">
                                    <i data-feather="mail" class="w-4 h-4 absolute top-3 start-4"></i>
                                    <input 
                                        type="email" 
                                        class="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" 
                                        placeholder="Email"
                                        formControlName="email"
                                    >
                                </div>
                            </div>
 
                        </div><!--end grid-->

                        <div class="p-6 dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-6 text-end">
        
                            <button 
                                (click)="updateRegister()"
                                class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-green-700 text-white rounded-md">
                                Actualizar Datos
                            </button>

                        </div>

                    </form><!--end form-->
                </div>

                <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-6">
                    <div class=" gap-5">
                        <div>
                            <h5 class="text-lg font-semibold mb-4">Cambiar Contraseña :</h5>

                            <form [formGroup]="form" autocomplete="off">
                                <div class="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                    <div>
                                        <label class="form-label font-medium">Nueva Contraseña : <span class="text-red-600">*</span></label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="key" class="w-4 h-4 absolute top-3 start-4"></i>
                                            <input 
                                                type="password" 
                                                class="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" 
                                                placeholder="Contraseña:" 
                                                formControlName="password"
                                            >
                                        </div>
                                    </div>
                                    <div>
                                        <label class="form-label font-medium">Confirmar Nueva Contraseña : <span class="text-red-600">*</span></label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="key" class="w-4 h-4 absolute top-3 start-4"></i>
                                            <input 
                                                type="password" 
                                                class="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" 
                                                placeholder="Confirmar Contraseña"
                                                formControlName="password_confirmation"
                                            >
                                        </div>
                                    </div>
         
                                </div><!--end grid-->

                                <div class="p-6 dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-6 text-end">
        
                                    <button 
                                        (click)="updatePassword()"
                                        class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-green-700 text-white rounded-md">
                                        Actualizar Contraseña
                                    </button>
        
                                </div>
        
                            </form><!--end form-->

                        </div><!--end col-->


                    </div><!--end row-->
                </div>

            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>
