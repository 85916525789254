import { CommonModule } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NavbarComponent } from '../../../components/navbar/navbar.component';
import { MobileAppComponent } from '../../../components/mobile-app/mobile-app.component';
import { ProductsService } from '@services/products.service';
import { UserMethodsComponent } from "../../account/user-methods/user-methods.component";
import { ContactsService } from '@services/contact.service';
import { RatesComponent } from "../../../components/rates/rates.component";
import { OrdersService } from '@services/orders.service';

import Swal from 'sweetalert2';
import { AddressListComponent } from "../../../components/address-list/address-list.component";
import { PaymentMethodsService } from '@services/payment-methods.service';

@Component({
  selector: 'app-shop-checkout',
  standalone: true,
  imports: [CommonModule, RouterLink, NavbarComponent, MobileAppComponent, UserMethodsComponent, RatesComponent, AddressListComponent],
  templateUrl: './shop-checkout.component.html'
})
export class ShopCheckoutComponent {

  private productService = inject(ProductsService)
  private contactsService = inject(ContactsService)
  private orderService = inject(OrdersService)
  private paymentMethodsService = inject(PaymentMethodsService)

  shoppingCart = this.productService.shoppingCart
  contactSelect =this.contactsService.contactSelect
  rateSelect = this.orderService.rateselect
  methodPayment = this.paymentMethodsService.methodsPreferred

  
  
  isComplete:boolean


  constructor(private router: Router){

    this.productService.getShoppingCart()

    effect(()=>{

      if (this.rateSelect()) {
        this.validateCompleted()
      }

      if (this.contactSelect()) {
        this.validateCompleted()
      }

    })

  }

  getRatesShipping(){

    if (this.contactSelect().id) {

      this.orderService.getRateShipping(this.contactSelect().id)

    }else{

      Swal.fire({

        text: 'No existe un domicilio de envío seleccionado',
        icon: 'error', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
        position: "bottom-end",
        showConfirmButton:false,
        timer: 2500
      });

    }

  }

  validateCompleted(){

    if (this.contactSelect().id && this.rateSelect().rateToken) {
      this.isComplete = true
    }

  }

  createOrder(){

    let data = {
      rateToken: this.rateSelect().rateToken,
      paymentMethod: this.methodPayment().id,
      id_invoice: this.shoppingCart().id,
      totalPayment: this.shoppingCart().amount
    }

    this.orderService.createOrderEcommerce(data)
    .subscribe(response =>{
      
      if (response.status === 200) {
        const url = `/order-success/${response.data.key}`
        this.router.navigate([url])
        this.productService.getShoppingCart()
      }
      else{

        Swal.fire({

          text: response.message,
          icon: 'error', // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
          position: "bottom-end",
          showConfirmButton:true,
          timer: 2500
        });
        
      }

    })

  }


}
